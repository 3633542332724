import React from "react";
import { Redirect, Navigate } from "react-router-dom";

// Authentication related pages
import Logout from "../pages/Authentication/components/Logout";
import LockScreen from "../pages/Authentication/components/LockScreen"
import Login from "../pages/Authentication/components/Login";

// Property Appz Import
import Dashboard from "../pages/Dashboard/Dashboard";
import ListRoles from "../pages/Roles/components/ListRoles"
import AddRole from "../pages/Roles/components/AddRole"
import EditRole from "../pages/Roles/components/EditRole"
import ListPlatformRoles from "../pages/PlatformRoles/components/ListPlatformRoles"
import AddPlatformRole from "../pages/PlatformRoles/components/AddPlatformRole"
import EditPlatformRole from "../pages/PlatformRoles/components/EditPlatformRole"
import ListUsers from "../pages/Users/components/ListUsers"
import AddUser from "../pages/Users/components/AddUser"
import AddProjectType from "../pages/Catalogue/ProjectType/components/AddProjectType"
import ListProjectTypes from "../pages/Catalogue/ProjectType/components/ListProjectTypes"
import AddPropertyType from "../pages/Catalogue/PropertyType/components/AddPropertyType"
import ListPropertyTypes from "../pages/Catalogue/PropertyType/components/ListPropertyTypes"
import AddPropertyFeature from "../pages/Catalogue/PropertyFeatures/components/AddPropertyFeature"
import ListPropertyFeatures from "../pages/Catalogue/PropertyFeatures/components/ListPropertyFeatures"
import AddCurrency from "pages/Catalogue/Currencies/components/AddCurrency";
import ListCurrencies from "pages/Catalogue/Currencies/components/ListCurrencies";
import AddDocumentType from "pages/Catalogue/DocumentTypes/components/AddDocumentType";
import ListDocumentTypes from "pages/Catalogue/DocumentTypes/components/ListDocumentTypes";
import Platform_OrganisationSetup from "pages/Platform_OrganisationSetup/components/SetupOrganisation";
import Organisation_OrganisationSetup from "pages/Organisation_OrganisationSetup/components/SetupOrganisation"
import AddProject from "pages/Projects/components/AddProject";
import ListProjects from "pages/Projects/components/ListProjects";
import AddProperty from "pages/Properties/components/AddProperty";
import ListProperties from "pages/Properties/components/ListProperties";
import AddCustomer from "pages/Customers/components/AddCustomer";
import ListCustomers from "pages/Customers/components/ListCustomers";
import EditCustomer from "pages/Customers/components/EditCustomer";
import ViewCustomer from "pages/Customers/components/ViewCustomer";
import ListDocuments from "pages/Customers/components/ListDocuments";
import AddIntegration from "pages/Integration/components/AddIntegration";
import ListIntegrations from "pages/Integration/components/ListIntegrations";
import EditIntegration from "pages/Integration/components/EditIntegration";
import AddCountry from "pages/Catalogue/Countries/components/AddCountry";
import AddCity from "pages/Catalogue/Cities/components/AddCity";
import ListCountries from "pages/Catalogue/Countries/components/ListCountries";
import ListCities from "pages/Catalogue/Cities/components/ListCities";
import AddSalesAgent from "pages/SalesAgents/components/AddSalesAgent";
import ListSalesAgents from "pages/SalesAgents/components/ListSalesAgents";
import EditSalesAgent from "pages/SalesAgents/components/EditSalesAgent";
import ViewSalesAgent from "pages/SalesAgents/components/ViewSalesAgent";
import LoginAdmin from "pages/Authentication/components/LoginAdmin";
import EditProject from "pages/Projects/components/EditProject";
import EditSizes from "pages/Projects/components/EditProject/BuildSizes";
import AddNominees from "pages/Customers/components/Nominees/AddNominees";
import ListNominees from "pages/Customers/components/Nominees/ListNominees";
import EditNominee from "pages/Customers/components/Nominees/EditNominee";
import ListDocumentsNominees from "pages/Customers/components/Nominees/ListDocumentsNominees";
import ListNomineeDocuments from "pages/Customers/components/Nominees/ListNomineeDocuments";
import ViewNominee from "pages/Customers/components/Nominees/ViewNominee";
import EditUser from "pages/Users/components/EditUser";
import OrgAddUser from "pages/OrganisationUsers/components/AddUser";
import OrgEditUser from "pages/OrganisationUsers/components/EditUser";
import OrgListUsers from "pages/OrganisationUsers/components/ListUsers";
import EditProperty from "pages/Properties/components/EditProperty";
import AddSale from "pages/Sales/components/AddSale";
import AddPaymentType from "pages/Catalogue/PaymentTypes/components/AddPaymentType";
import ListPaymentTypes from "pages/Catalogue/PaymentTypes/components/ListPaymentTypes";
import ListSales from "pages/Sales/components/ListSales";
import EditPhasesBlocks from "pages/Projects/components/EditProject/BuildPhasesBlocks";
import EditFloors from "pages/Projects/components/EditProject/BuildFloors";
import EditProjectCurrency from "pages/Projects/components/EditProject/EditProjectCurrency";
import EditSale from "pages/Sales/components/EditSale";
import ViewSale from "pages/Sales/components/ViewSale";

import ListPayments from "pages/Payments/components/ListPayments";
import ReceivePayment from "pages/Payments/components/ReceivePayment";

import AddPaymentPlan from "../pages/PaymentPlans/components/AddPaymentPlan"
import ListPaymentPlans from "../pages/PaymentPlans/components/ListPaymentPlans"
import EditPaymentPlan from "pages/PaymentPlans/components/EditPaymentPlan";
import ViewPaymentPlan from "pages/PaymentPlans/components/ViewPaymentPlan";
import AddProjectFeature from "pages/Projects/components/AddProjectFeature";
import EditProjectFeature from "pages/Projects/components/EditProject/EditProjectFeature";
import ListDuePayments from "pages/Reports/components/ListDuePayments";
import ViewProject from "pages/Projects/components/ViewProject";
import ViewProperty from "pages/Properties/components/ViewProperty";
import DashboardPlatform from "pages/Dashboard/DashboardPlatform";

import EditProfiles from "pages/Profiles/components/EditProfiles";
import UserSettings from "pages/Settings/components/UserSettings";

import ListIdentityCustomers from "pages/Documents/components/ListIdentityCustomers";
import ListSalesDocs from "pages/Documents/components/ListSalesDocs";
import SaleDocuments from "pages/Documents/components/SaleDocuments";
import ListPaymentsDocs from "pages/Documents/components/ListPaymentsDocs";
import PaymentDocumentsList from "pages/Documents/components/PaymentDocumentsList";
import ListProjectReports from "pages/Reports/components/ListProjectReports";
import ListPropertiesAvailable from "pages/Properties/components/ListPropertiesAvailable";
import ListPropertiesSold from "pages/Properties/components/ListPropertiesSold";
import ListPropertiesTotal from "pages/Properties/components/ListPropertiesTotal";
import ProjectLog from "pages/Projects/components/ProjectLog";
import ListDeletedProjects from "pages/Projects/components/ListDeletedProjects";
import ProjectsLogs from "pages/Projects/components/ProjectsLogs";
import PropertyLog from "pages/Properties/components/PropertyLog";
import PropertiesLogs from "pages/Properties/components/PropertiesLogs";
import ListDeletedProperties from "pages/Properties/components/ListDeletedProperties";
import PaymentPlanLog from "pages/PaymentPlans/components/PaymentPlanLog";
import PaymentPlansLogs from "pages/PaymentPlans/components/PaymentPlansLogs";
import ListDeletedPaymentPlans from "pages/PaymentPlans/components/ListDeletedPaymentPlans";
import SaleLog from "pages/Sales/components/SaleLog";
import SalesLogs from "pages/Sales/components/SalesLogs";
import ListCancelledSales from "pages/Sales/components/ListCancelledSales";
import PaymentsLog from "pages/Sales/components/PaymentsLog";
import PaymentLog from "pages/Sales/components/PaymentLog";
import PaymentView from "pages/Payments/components/PaymentView";
import CustomerDocumentsLogs from "pages/Documents/components/CustomerDocumentsLogs";
import NomineeDocumentsLogs from "pages/Documents/components/NomineeDocumentsLogs";
import IdentityDocumentsLogs from "pages/Documents/components/IdentityDocumentsLogs";
import CustomerLog from "pages/Customers/components/CustomerLog";
import CustomersLogs from "pages/Customers/components/CustomersLogs";
import DeletedCustomersList from "pages/Customers/components/DeletedCustomersList";
import NomineeLog from "pages/Customers/components/Nominees/NomineeLog";
import DeletedNomineesList from "pages/Customers/components/Nominees/DeletedNomineesList";
import SalesAgentLog from "pages/SalesAgents/components/SalesAgentLog";
import SalesAgentsLogs from "pages/SalesAgents/components/SalesAgentsLogs";
import DeletedSalesAgentsList from "pages/SalesAgents/components/DeletedSalesAgentsList";
import ListWelcomeLetterSamples from "pages/Templates/Documents/Sales/components/WelcomeLetter/ListWelcomeLetterSamples";
import TabView from "pages/Templates/Documents/Sales/components/TabView";
import CloneWelcomeTemplate from "pages/Templates/Documents/Sales/components/WelcomeLetter/CloneWelcomeTemplate";
import ListCloneWelcomeLetters from "pages/Templates/Documents/Sales/components/WelcomeLetter/ListCloneWelcomeLetters";
import ListPaymentSummarySamples from "pages/Templates/Documents/Sales/components/PaymentSummary/ListPaymentSummarySamples";
import ClonePaymentSummaryTemplate from "pages/Templates/Documents/Sales/components/PaymentSummary/ClonePaymentSummaryTemplate";
import ListClonePaymentSummary from "pages/Templates/Documents/Sales/components/PaymentSummary/ListClonePaymentSummary";
import ListStatementAccountsSamples from "pages/Templates/Documents/Sales/components/StatementOfAccounts/ListStatementAccountsSamples";
import CloneStatementAccountsTemplate from "pages/Templates/Documents/Sales/components/StatementOfAccounts/CloneStatementAccountsTemplate";
import ListCloneStatementAccounts from "pages/Templates/Documents/Sales/components/StatementOfAccounts/ListCloneStatementAccounts";
import TabViewFinancials from "pages/Templates/Documents/Financial/components/TabView"
import ListReceiptSamples from "pages/Templates/Documents/Financial/components/Receipt/ListReceiptSamples";
import CloneReceiptTemplate from "pages/Templates/Documents/Financial/components/Receipt/CloneReceiptTemplate";
import ListCloneReceipt from "pages/Templates/Documents/Financial/components/Receipt/ListCloneReceipt";
import EditWelcomeLetter from "pages/Templates/Documents/Sales/components/WelcomeLetter/EditWelcomeLetter";
import EditPaymentSummary from "pages/Templates/Documents/Sales/components/PaymentSummary/EditPaymentSummary";
import EditStatementAccounts from "pages/Templates/Documents/Sales/components/StatementOfAccounts/EditStatementAccounts";
import EditReceipt from "pages/Templates/Documents/Financial/components/Receipt/EditReceipt";
import ListInvoiceSamples from "pages/Templates/Documents/Financial/components/Invoice/ListInvoiceSamples";
import CloneInvoiceTemplate from "pages/Templates/Documents/Financial/components/Invoice/CloneInvoiceTemplate";
import ListCloneInvoice from "pages/Templates/Documents/Financial/components/Invoice/ListCloneInvoice";
import EditInvoice from "pages/Templates/Documents/Financial/components/Invoice/EditInvoice";
import ListPaymentScheduleSamples from "pages/Templates/Documents/Sales/components/PaymentSchedule/ListPaymentScheduleSamples";
import ClonePaymentScheduleTemplate from "pages/Templates/Documents/Sales/components/PaymentSchedule/ClonePaymentScheduleTemplate";
import ListClonePaymentSchedule from "pages/Templates/Documents/Sales/components/PaymentSchedule/ListClonePaymentSchedule";
import EditPaymentSchedule from "pages/Templates/Documents/Sales/components/PaymentSchedule/EditPaymentSchedule";
import PaymentList from "pages/Documents/components/PaymentList";
import WelcomeLetterLogs from "pages/Templates/Documents/Sales/components/WelcomeLetter/WelcomeLetterLogs";
import PaymentSummaryLogs from "pages/Templates/Documents/Sales/components/PaymentSummary/PaymentSummaryLogs";
import StatementAccountsLogs from "pages/Templates/Documents/Sales/components/StatementOfAccounts/StatementAccountsLogs";
import PaymentScheduleLogs from "pages/Templates/Documents/Sales/components/PaymentSchedule/PaymentScheduleLogs";
import InvoiceLogs from "pages/Templates/Documents/Financial/components/Invoice/InvoiceLogs";
import ReceiptLogs from "pages/Templates/Documents/Financial/components/Receipt/ReceiptLogs";
import TemplatesDocumentsLogs from "pages/Templates/Documents/TemplatesDocumentsLogs";
import TabViewSalesEmail from "pages/Templates/Email/Sales/components/TabView"
import TabViewFinancialEmail from "pages/Templates/Email/Financial/components/TabView"
import ListWelcomePackSamples from "pages/Templates/Email/Sales/components/WelcomePack/ListWelcomePackSamples";
import CloneWelcomePackTemplate from "pages/Templates/Email/Sales/components/WelcomePack/CloneWelcomePackTemplate";
import ListCloneWelcomePack from "pages/Templates/Email/Sales/components/WelcomePack/ListCloneWelcomePack";
import EditWelcomePack from "pages/Templates/Email/Sales/components/WelcomePack/WelcomePackEdit/EditWelcomePack";
import ListInvoiceEmailSamples from "pages/Templates/Email/Financial/components/InvoiceEmail/ListInvoiceEmailSamples";
import CloneInvoiceEmailTemplate from "pages/Templates/Email/Financial/components/InvoiceEmail/CloneInvoiceEmailTemplate";
import ListCloneInvoiceEmail from "pages/Templates/Email/Financial/components/InvoiceEmail/ListCloneInvoiceEmail";
import EditInvoiceEmail from "pages/Templates/Email/Financial/components/InvoiceEmail/InvoiceEmailEdit/EditInvoiceEmail";
import ListReceiptEmailSamples from "pages/Templates/Email/Financial/components/ReceiptEmail/ListReceiptEmailSamples";
import CloneReceiptEmailTemplate from "pages/Templates/Email/Financial/components/ReceiptEmail/CloneReceiptEmailTemplate";
import ListCloneReceiptEmail from "pages/Templates/Email/Financial/components/ReceiptEmail/ListCloneReceiptEmail";
import EditReceiptEmail from "pages/Templates/Email/Financial/components/ReceiptEmail/ReceiptEmailEdit/EditReceiptEmail";
import ListPaymentReminderEmailSamples from "pages/Templates/Email/Financial/components/PaymentReminderEmail/ListPaymentReminderEmailSamples";
import ClonePaymentReminderEmailTemplate from "pages/Templates/Email/Financial/components/PaymentReminderEmail/ClonePaymentReminderEmailTemplate";
import ListClonePaymentReminderEmail from "pages/Templates/Email/Financial/components/PaymentReminderEmail/ListClonePaymentReminderEmail";
import EditPaymentReminderEmail from "pages/Templates/Email/Financial/components/PaymentReminderEmail/PaymentReminderEmailEdit/EditPaymentReminderEmail";
import ListPaymentSummarySamplesEmail from "pages/Templates/Email/Sales/components/PaymentSummary/ListPaymentSummarySamples";
import ClonePaymentSummaryEmailTemplate from "pages/Templates/Email/Sales/components/PaymentSummary/ClonePaymentSummaryEmailTemplate";
import ListClonePaymentSummaryEmail from "pages/Templates/Email/Sales/components/PaymentSummary/ListClonePaymentSummaryEmail";
import EditPaymentSummaryEmail from "pages/Templates/Email/Sales/components/PaymentSummary/PaymentSummaryEdit/EditPaymentSummaryEmail";
import ListStatementOfAccountSamples from "../pages/Templates/Email/Sales/components/StatementOfAccount/ListStatementOfAccountSamples";
import CloneStatementOfAccountEmailTemplate from "pages/Templates/Email/Sales/components/StatementOfAccount/CloneStatementOfAccountEmailTemplate";
import ListCloneStatementOfAccountEmail from "pages/Templates/Email/Sales/components/StatementOfAccount/ListCloneStatementOfAccountEmail";
import EditStatementOfAccountEmail from "pages/Templates/Email/Sales/components/StatementOfAccount/StatementOfAccountEdit/EditStatementOfAccountEmail";
import WelcomePackEmailLogs from "pages/Templates/Email/Sales/components/WelcomePack/WelcomePackEmailLogs";
import InvoiceEmailLogs from "pages/Templates/Email/Financial/components/InvoiceEmail/InvoiceEmailLogs";
import ReceiptEmailLogs from "pages/Templates/Email/Financial/components/ReceiptEmail/ReceiptEmailLogs";
import StatementOfAccountEmailLogs from "pages/Templates/Email/Sales/components/StatementOfAccount/StatementOfAccountEmailLogs";
import PaymentReminderEmailLogs from "pages/Templates/Email/Financial/components/PaymentReminderEmail/PaymentReminderEmailLogs";
import PaymentSummaryEmailLogs from "pages/Templates/Email/Sales/components/PaymentSummary/PaymentSummaryEmailLogs";
import SaleDocsLog from "pages/Documents/components/SaleDocsLog";
import SalesDocumentsAllLogs from "pages/Documents/components/SalesDocumentsAllLogs";
import PaymentDocsLog from "pages/Documents/components/PaymentDocsLog";
import PaymentsDocumentsAllLogs from "pages/Documents/components/PaymentsDocumentsAllLogs";
import TemplateEmailLogs from "pages/Templates/Email/TemplatesEmailLogs";
import SalesReport from "pages/Reports/components/SalesReport";
import PaymentsReport from "pages/Reports/components/PaymentsReport";
import OverduePaymentsReport from "pages/Reports/components/OverduePaymentsReport";
import ListSalesReceipts from "pages/Payments/components/ListSalesReceipts";
import ReceiptView from "pages/Payments/components/ReceiptView";
import PaymentReceiptView from "pages/Payments/components/PaymentReceiptView";
import ListReceiptDocuments from "pages/Documents/components/FinancialDocuments/ListReceiptDocuments";
import PaymentsDocumentsReceiptLogs from "pages/Documents/components/FinancialDocuments/PaymentsDocumentsReceiptLogs";
import AddLogType from "pages/Catalogue/LogType/components/AddLogType";
import ListLogTypes from "pages/Catalogue/LogType/components/ListLogTypes";
import AddCampaignType from "pages/Catalogue/CampaignType/components/AddCampaignType";
import ListCampaignTypes from "pages/Catalogue/CampaignType/components/ListCampaignTypes";
import AddLeadStage from "pages/Catalogue/LeadStage/components/AddLeadStage";
import ListLeadStages from "pages/Catalogue/LeadStage/components/ListLeadStages";
import AddLeadPipeline from "pages/Catalogue/LeadPipeline/components/AddLeadPipeline";
import ListLeadPipelines from "pages/Catalogue/LeadPipeline/components/ListLeadPipelines";
import AddLeadType from "pages/Catalogue/LeadType/components/AddLeadType";
import ListLeadTypes from "pages/Catalogue/LeadType/components/ListLeadTypes";
import AddCampaign from "pages/Campaigns/components/AddCampaign";
import ListCampaigns from "pages/Campaigns/components/ListCampaigns";
import EditCampaign from "pages/Campaigns/components/EditCampaign";
import AddLead from "pages/Leads/components/AddLead";
import PublicAPISettings from "pages/LeadsSettings/PublicApiSettings/components/PublicAPISettings";
import ListLeads from "pages/Leads/components/ListLeads";
import utms_post from "pages/UTMs/components/utms_post";
import LeadView from "pages/Leads/components/LeadView";
import AddPropertyAppzUser from "pages/PropertyAppz/Users/components/AddUser";
import ListPropertyAppzUser from "pages/PropertyAppz/Users/components/ListUsers";
import EditPropertyAppzUser from "pages/PropertyAppz/Users/components/EditUser";
import LoginPropertyAppz from "pages/Authentication/components/LoginPropertyAppz";
import AddPropertyAppzRole from "pages/PropertyAppz/Roles/components/AddRole";
import ListPropertyAppzRole from "pages/PropertyAppz/Roles/components/ListRoles";
import EditPropertyAppzRole from "pages/PropertyAppz/Roles/components/EditRole";
import AddTenant from "pages/PropertyAppz/Tenants/components/AddTenant";
import ListTenants from "pages/PropertyAppz/Tenants/components/ListTenants";
import ViewTenant from "pages/PropertyAppz/Tenants/components/ViewTenant";
import EditTenant from "pages/PropertyAppz/Tenants/components/EditTenant";
import AddBank from "pages/BankDetails/components/AddBank";
import ListBanks from "pages/BankDetails/components/ListBanks";
import EditBank from "pages/BankDetails/components/EditBank";
import CampaignsLogs from "pages/Campaigns/components/CampaignsLogs";
import CampaignLogs from "pages/Campaigns/components/CampaignLogs";
import LeadsLogs from "pages/Leads/components/LeadsLogs";
import LeadLogs from "pages/Leads/components/LeadLogs";
import LeadSettingsLogs from "pages/LeadsSettings/PublicApiSettings/components/LeadSettingsLogs";
import IPManagement from "pages/Settings/components/IPManagement";
const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/platform/dashboard", component: DashboardPlatform },

  // Platform User roles
  { path: "/platformroles/list", component: ListPlatformRoles },
  { path: "/platformroles/add", component: AddPlatformRole },
  { path: "/platformroles/edit", component: EditPlatformRole },

  // Platform users
  { path: "/users/list", component: ListUsers },
  { path: "/users/add", component: AddUser },
  { path: "/user/edit", component: EditUser },

  // Organisation users
  { path: "/organisation/users/list", component: OrgListUsers },
  { path: "/organisation/users/add", component: OrgAddUser },
  { path: "/organisation/user/edit", component: OrgEditUser },

  // Organisation Banks Details
  { path: "/organisation/bank/add", component: AddBank },
  { path: "/organisation/bank/list", component: ListBanks },
  { path: "/organisation/bank/edit", component: EditBank },

  //Catalogue -> Project Types
  { path: "/catalogue/project-type/add", component: AddProjectType },
  { path: "/catalogue/project-type/list", component: ListProjectTypes },

  //Catalogue -> Property Types
  { path: "/catalogue/property-type/add", component: AddPropertyType },
  { path: "/catalogue/property-type/list", component: ListPropertyTypes },

  //Catalogue -> Property Features
  { path: "/catalogue/property-feature/add", component: AddPropertyFeature },
  { path: "/catalogue/property-feature/list", component: ListPropertyFeatures },

  //Catalogue -> Currencies
  { path: "/catalogue/currencies/add", component: AddCurrency },
  { path: "/catalogue/currencies/list", component: ListCurrencies },

  //Catalogue -> Document Types
  // { path: "/catalogue/document-types/add", component: AddDocumentType },
  { path: "/catalogue/document-types/list", component: ListDocumentTypes },

  //Catalogue -> Countries
  { path: "/catalogue/country/add", component: AddCountry },
  { path: "/catalogue/country/list", component: ListCountries },

  //Catalogue -> Cities
  { path: "/catalogue/cities/add", component: AddCity },
  { path: "/catalogue/cities/list", component: ListCities },

  //Catalogue -> Payment Types
  { path: "/catalogue/payment-type/add", component: AddPaymentType },
  { path: "/catalogue/payment-type/list", component: ListPaymentTypes },

  // Catalogue -> Log Types
  { path: "/catalogue/log-type/add", component: AddLogType },
  { path: "/catalogue/log-type/list", component: ListLogTypes },

  // Catalogue -> Campaign Types
  { path: "/catalogue/campaign-type/add", component: AddCampaignType },
  { path: "/catalogue/campaign-type/list", component: ListCampaignTypes },

  // Catalogue -> Lead Stages
  { path: "/catalogue/lead-stage/add", component: AddLeadStage },
  { path: "/catalogue/lead-stage/list", component: ListLeadStages },

  // Catalogue -> Lead Pipelines
  { path: "/catalogue/lead-pipeline/add", component: AddLeadPipeline },
  { path: "/catalogue/lead-pipeline/list", component: ListLeadPipelines },

  // Catalogue -> Lead Types
  { path: "/catalogue/lead-type/add", component: AddLeadType },
  { path: "/catalogue/lead-type/list", component: ListLeadTypes },

  //Integration -> Add Integration
  { path: "/integrations/add", component: AddIntegration },
  { path: "/integrations/list", component: ListIntegrations },
  { path: "/integrations/edit", component: EditIntegration },

  //Platform Organisation Setup -> Setup Organisation
  { path: "/organisation-setup/setup", component: Platform_OrganisationSetup },

  //Organisation Organisation Setup -> Setup Organisation
  { path: "/setting/organisation/setup", component: Organisation_OrganisationSetup },

  // Organisation -> Projects
  { path: "/projects/add", component: AddProject },
  { path: "/projects/phases", component: AddProject },
  { path: "/projects/floors", component: AddProject },
  { path: "/projects/sizes", component: AddProject },
  { path: "/projects/currencies", component: AddProject },
  { path: "/projects/features", component: AddProject },

  { path: "/project/edit", component: EditProject },
  { path: "/project/edit-sizes", component: EditSizes },
  { path: "/project/edit-phases-blocks", component: EditPhasesBlocks },
  { path: "/project/edit-floors", component: EditFloors },
  { path: "/projects/edit-currencies", component: EditProjectCurrency },
  { path: "/projects/edit-features", component: EditProjectFeature },

  { path: "/projects/list", component: ListProjects },
  { path: "/project/view", component: ViewProject },
  { path: "/project/log", component: ProjectLog },
  { path: "/deleted/projects/list", component: ListDeletedProjects },
  { path: "/all/projects/logs", component: ProjectsLogs },

  // Organisation -> Properties
  { path: "/properties/add", component: AddProperty },
  { path: "/properties/list", component: ListProperties },
  { path: "/project-report/properties/available/list", component: ListPropertiesAvailable },
  { path: "/project-report/properties/sold/list", component: ListPropertiesSold },
  { path: "/project-report/properties/total/list", component: ListPropertiesTotal },
  { path: "/property/edit", component: EditProperty },
  { path: "/property/view", component: ViewProperty },
  { path: "/property/log", component: PropertyLog },
  { path: "/deleted/properties/list", component: ListDeletedProperties },
  { path: "/all/properties/logs", component: PropertiesLogs },

  // Organisation -> Payment Plans
  { path: "/payment-plan/add", component: AddPaymentPlan },
  { path: "/payment-plan/list", component: ListPaymentPlans },
  { path: "/payment-plan/edit", component: EditPaymentPlan },
  { path: "/payment-plan/view", component: ViewPaymentPlan },
  { path: "/payment-plan/log", component: PaymentPlanLog },
  { path: "/all/payment-plan/log", component: PaymentPlansLogs },
  { path: "/deleted/payment-plans/list", component: ListDeletedPaymentPlans },

  // Organisation -> Customers
  { path: "/customers/add", component: AddCustomer },
  { path: "/customers/list", component: ListCustomers },
  { path: "/customers/edit", component: EditCustomer },
  { path: "/customers/view", component: ViewCustomer },
  { path: "/customers/documents", component: ListDocuments },
  { path: "/customer/nominee/add", component: AddNominees },
  { path: "/customer/nominees/list", component: ListNominees },
  { path: "/customer/nominee/edit", component: EditNominee },
  { path: "/customer/documents_nominees/list", component: ListDocumentsNominees },
  { path: "/customers/nominee/documents", component: ListNomineeDocuments },
  { path: "/customer/nominee/view", component: ViewNominee },
  { path: "/customer/log", component: CustomerLog },
  { path: "/customers/logs", component: CustomersLogs },
  { path: "/deleted/customers/list", component: DeletedCustomersList },
  { path: "/customer/nominee/log", component: NomineeLog },
  { path: "/customer/deleted/nominees/list", component: DeletedNomineesList },

  { path: "/salesagents/add", component: AddSalesAgent },
  { path: "/salesagents/list", component: ListSalesAgents },
  { path: "/salesagents/edit", component: EditSalesAgent },
  { path: "/salesagents/view", component: ViewSalesAgent },
  { path: "/salesagents/log", component: SalesAgentLog },
  { path: "/all/salesagents/logs", component: SalesAgentsLogs },
  { path: "/deleted/salesagents/list", component: DeletedSalesAgentsList },

  // Organisation roles
  { path: "/roles/list", component: ListRoles },
  { path: "/roles/add", component: AddRole },
  { path: "/roles/edit", component: EditRole },

  // Sales
  { path: "/sales/add", component: AddSale },
  { path: "/sales/list", component: ListSales },
  { path: "/sales/edit", component: EditSale },
  { path: "/sale/log", component: SaleLog },
  { path: "/sale/view", component: ViewSale },
  { path: "/all/sales/logs", component: SalesLogs },
  { path: "/cancelled/sales/list", component: ListCancelledSales },
  { path: "/payments/log", component: PaymentsLog },
  { path: "/payment/log", component: PaymentLog },
  { path: "/payment/view", component: PaymentView },

  // Sale payments
  { path: "/salepayments/list", component: ListPayments },
  { path: "/sales/receipts", component: ListSalesReceipts },
  { path: "/sales/receipt/view", component: ReceiptView },
  { path: "/payment/receipt/view", component: PaymentReceiptView },
  // This route not using for now.
  // { path: "/receivepayment", component: ReceivePayment },

  //Reports Module
  { path: "/report/sales", component: SalesReport },
  { path: "/report/payments", component: PaymentsReport },
  { path: "/report/overdue-payments", component: OverduePaymentsReport },
  // { path: "/reports/due-payments", component: ListDuePayments },
  { path: "/reports/projects", component: ListProjectReports },

  { path: "/documents/identity", component: ListIdentityCustomers },
  { path: "/documents/sales", component: ListSalesDocs },
  { path: "/sale/documents", component: SaleDocuments },
  { path: "/payments/documents", component: ListPaymentsDocs },
  { path: "/salepayment/receipt-documents", component: ListReceiptDocuments },
  { path: "/payments-documents/log", component: PaymentDocsLog },
  { path: "/all/documents/payments/logs", component: PaymentsDocumentsAllLogs },
  { path: "/documents/payments/receipt/logs", component: PaymentsDocumentsReceiptLogs },
  { path: "/salepayment/documents", component: PaymentList },
  { path: "/salepayment/documents/list", component: PaymentDocumentsList },
  { path: "/customer-documents/log", component: CustomerDocumentsLogs },
  { path: "/nominee-documents/log", component: NomineeDocumentsLogs },
  { path: "/all/documents/identity/logs", component: IdentityDocumentsLogs },
  { path: "/sale-documents/log", component: SaleDocsLog },
  { path: "/all/documents/sales/logs", component: SalesDocumentsAllLogs },

  // Templates Sales
  { path: "/templates/documents-templates/sales-documents-tabs-view", component: TabView },
  { path: "/template/documents/sales/welcome-letter/samples-list", component: ListWelcomeLetterSamples },
  { path: "/template/documents/sales/welcome-letter/sample-clone", component: CloneWelcomeTemplate },
  { path: "/template/documents/sales/welcome-letter/clone-list", component: ListCloneWelcomeLetters },
  { path: "/template/documents/sales/payment-summary/samples-list", component: ListPaymentSummarySamples },
  { path: "/template/documents/sales/payment-summary/sample-clone", component: ClonePaymentSummaryTemplate },
  { path: "/template/documents/sales/payment-summary/clone-list", component: ListClonePaymentSummary },
  { path: "/template/documents/sales/statement-accounts/samples-list", component: ListStatementAccountsSamples },
  { path: "/template/documents/sales/statement-accounts/sample-clone", component: CloneStatementAccountsTemplate },
  { path: "/template/documents/sales/statement-accounts/clone-list", component: ListCloneStatementAccounts },
  { path: "/template/documents/sales/payment-schedule/samples-list", component: ListPaymentScheduleSamples },
  { path: "/template/documents/sales/payment-schedule/sample-clone", component: ClonePaymentScheduleTemplate },
  { path: "/template/documents/sales/payment-schedule/clone-list", component: ListClonePaymentSchedule },
  { path: "/template/documents/sales/payment-schedule/clone-list/edit", component: EditPaymentSchedule },
  { path: "/template/documents/sales/welcome-letter/logs", component: WelcomeLetterLogs },
  { path: "/template/documents/sales/payment-summary/logs", component: PaymentSummaryLogs },
  { path: "/template/documents/sales/statement-accounts/logs", component: StatementAccountsLogs },
  { path: "/template/documents/sales/payment-schedule/logs", component: PaymentScheduleLogs },

  // Template Financials
  { path: "/templates/documents-templates/financials-documents-tabs-view", component: TabViewFinancials },
  { path: "/template/documents/financials/receipt/samples-list", component: ListReceiptSamples },
  { path: "/template/documents/financials/receipt/sample-clone", component: CloneReceiptTemplate },
  { path: "/template/documents/financials/receipt/clone-list", component: ListCloneReceipt },
  { path: "/template/documents/sales/welcome-letter/clone-list/edit", component: EditWelcomeLetter },
  { path: "/template/documents/sales/payment-summary/clone-list/edit", component: EditPaymentSummary },
  { path: "/template/documents/sales/statement-accounts/clone-list/edit", component: EditStatementAccounts },
  { path: "/template/documents/financials/receipt/clone-list/edit", component: EditReceipt },
  { path: "/template/documents/financials/invoice/samples-list", component: ListInvoiceSamples },
  { path: "/template/documents/financials/invoice/sample-clone", component: CloneInvoiceTemplate },
  { path: "/template/documents/financials/invoice/clone-list", component: ListCloneInvoice },
  { path: "/template/documents/financials/invoice/clone-list/edit", component: EditInvoice },
  { path: "/template/documents/financials/invoice/logs", component: InvoiceLogs },
  { path: "/template/documents/financials/receipt/logs", component: ReceiptLogs },
  { path: "/template/documents/logs", component: TemplatesDocumentsLogs },

  // Email Templates
  { path: "/templates/email-templates/sales-email-tabs-view", component: TabViewSalesEmail },
  { path: "/template/email/sales/welcome-pack/samples-list", component: ListWelcomePackSamples },
  { path: "/template/email/sales/welcome-pack/sample-clone", component: CloneWelcomePackTemplate },
  { path: "/template/email/sales/welcome-pack/clone-list", component: ListCloneWelcomePack },
  { path: "/template/email/sales/welcome-email/clone-list/edit", component: EditWelcomePack },
  { path: "/templates/email-templates/financial-email-tabs-view", component: TabViewFinancialEmail },
  { path: "/template/email/financial/invoice-email/samples-list", component: ListInvoiceEmailSamples },
  { path: "/template/email/financial/invoice-email/sample-clone", component: CloneInvoiceEmailTemplate },
  { path: "/template/email/financial/invoice-email/clone-list", component: ListCloneInvoiceEmail },
  { path: "/template/email/financial/invoice-email/clone-list/edit", component: EditInvoiceEmail },
  { path: "/template/email/financial/receipt-email/samples-list", component: ListReceiptEmailSamples },
  { path: "/template/email/financial/receipt-email/sample-clone", component: CloneReceiptEmailTemplate },
  { path: "/template/email/financial/receipt-email/clone-list", component: ListCloneReceiptEmail },
  { path: "/template/email/financial/receipt-email/clone-list/edit", component: EditReceiptEmail },
  { path: "/template/email/financial/payment-reminder-email/samples-list", component: ListPaymentReminderEmailSamples },
  { path: "/template/email/financial/payment-reminder-email/sample-clone", component: ClonePaymentReminderEmailTemplate },
  { path: "/template/email/financial/payment-reminder-email/clone-list", component: ListClonePaymentReminderEmail },
  { path: "/template/email/financial/payment-reminder-email/clone-list/edit", component: EditPaymentReminderEmail },
  { path: "/template/email/sales/payment-summary/samples-list", component: ListPaymentSummarySamplesEmail },
  { path: "/template/email/sales/payment-summary/sample-clone", component: ClonePaymentSummaryEmailTemplate },
  { path: "/template/email/sales/payment-summary-email/clone-list", component: ListClonePaymentSummaryEmail },
  { path: "/template/email/sales/payment-summary-email/clone-list/edit", component: EditPaymentSummaryEmail },
  { path: "/template/email/sales/statement-account/samples-list", component: ListStatementOfAccountSamples },
  { path: "/template/email/sales/statement-account/sample-clone", component: CloneStatementOfAccountEmailTemplate },
  { path: "/template/email/sales/statement-account-email/clone-list", component: ListCloneStatementOfAccountEmail },
  { path: "/template/email/sales/statement-account-email/clone-list/edit", component: EditStatementOfAccountEmail },
  { path: "/template/email/sales/welcome-pack/logs", component: WelcomePackEmailLogs },
  { path: "/template/email/financial/invoice-email/logs", component: InvoiceEmailLogs },
  { path: "/template/email/financial/receipt-email/logs", component: ReceiptEmailLogs },
  { path: "/template/email/sales/statement-account-email/logs", component: StatementOfAccountEmailLogs },
  { path: "/template/email/financial/payment-reminder-email/logs", component: PaymentReminderEmailLogs },
  { path: "/template/email/sales/payment-summary-email/logs", component: PaymentSummaryEmailLogs },
  { path: "/template/email/logs", component: TemplateEmailLogs },

  // Campaign Module
  { path: "/campaign/add", component: AddCampaign },
  { path: "/campaign/list", component: ListCampaigns },
  { path: "/campaigns/campaign-list/edit", component: EditCampaign },
  { path: "/campaigns/logs", component: CampaignsLogs },
  { path: "/campaign/logs", component: CampaignLogs },

  // Leads Module
  { path: "/lead/add", component: AddLead },
  { path: "/lead/list", component: ListLeads },
  { path: "/lead/view", component: LeadView },
  { path: "/leads/logs", component: LeadsLogs },
  { path: "/lead/logs", component: LeadLogs },


  // Public API Settings
  { path: "/leads-settings/public-api-settings", component: PublicAPISettings },
  { path: "/leads-settings/logs", component: LeadSettingsLogs },

  // Profile
  { path: "/user/profile", component: EditProfiles },

  // Settings
  { path: "/user/settings", component: UserSettings },

  // Propertyappz Users
  { path: "/propertyappz/users/add", component:  AddPropertyAppzUser},
  { path: "/propertyappz/users/list", component:  ListPropertyAppzUser},
  { path: "/propertyappz/users/edit", component:  EditPropertyAppzUser},

  // PropertyAppz Roles
  { path: "/propertyappz/roles/add", component: AddPropertyAppzRole },
  { path: "/propertyappz/roles/list", component: ListPropertyAppzRole },
  { path: "/propertyappz/roles/edit", component: EditPropertyAppzRole },

  // PropertyAppz Tenants
  { path: "/propertyappz/tenants/add", component: AddTenant },
  { path: "/propertyappz/tenants/list", component: ListTenants },
  { path: "/propertyappz/tenants/view", component: ViewTenant },
  { path: "/propertyappz/tenants/edit", component: EditTenant },

  // PropertyAppz IP Management 
  { path: "/propertyappz/ip-management/update", component: IPManagement },
  
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "*", exact: true, component: () => <Redirect to="/404-page" /> },
];

const publicRoutes = [
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/admin/login", component: LoginAdmin },
  { path: "/propertyappz/login", component: LoginPropertyAppz },
  { path: "/campaign/utms", component: utms_post },
];

export { authProtectedRoutes, publicRoutes };