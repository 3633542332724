import axios from "axios";
// import { Link, useHistory } from "react-router-dom";  
import { withRouter } from 'react-router-dom'

const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};

import constant from '../../../constants.json';
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");

export const get_lead_data = async (lead_id) => {
    let response = await axios.get(baseURL + "/api/leads/" + lead_id, config);
    return response.data.data;
}
export const get_activity_logs = async () => {
    let response = await axios.get(baseURL + "/api/lead-activity-logs", config);
    return response.data.data;
}
export const get_activity_logs_against_lead = async (lead_id) => {
    let response = await axios.get(baseURL + "/api/activity_log_against_lead/" + lead_id, config);
    return response.data.data;
}
export const get_activity_logs_notes_against_lead = async (lead_id) => {
    const params = new URLSearchParams({
        filterFlag: "Notes"
    });

    const response = await axios.get(`${baseURL}/api/activity_log_against_lead/${lead_id}?${params.toString()}`, config);
    return response.data.data;
};
export const delete_lead_avtivity_type = async (id) => {
    let response = await axios.delete(baseURL + "/api/lead-activity-logs/" + id, config);
    return response.data.data;
}
export const get_all_leads_logs = async () => {
    let response = await axios.get(baseURL + "/api/all-lead-activity-log", config);
    return response.data.data;
}
export const get_all_lead_logs = async (lead_id) => {
    let response = await axios.get(baseURL + "/api/lead-activity-log/" + lead_id, config);
    return response.data.data;
}