import axios from "axios";
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
export const get_all_organisation_banks = async () => {
    let response = await axios.get(baseURL + "/api/organisation-bank-details", config);
    return response.data.data;
}
export const get_all_platform_banks = async () => {
    let response = await axios.get(baseURL + "/api/platform-bank-details", config);
    return response.data.data;
}
export const get_single_organisation_bank = async (bank_id) => {
    let response = await axios.get(baseURL + "/api/organisation-bank-details/" + bank_id, config);
    return response.data.data;
}
export const get_single_platform_bank = async (bank_id) => {
    let response = await axios.get(baseURL + "/api/platform-bank-details/" + bank_id, config);
    return response.data.data;
}
export const update_organisation_bank = async (bank_id, payload) => {
    let response = await axios.patch(baseURL + "/api/organisation-bank-details/" + bank_id, payload, config);
    return response.data.data;
}
export const update_platform_bank = async (bank_id, payload) => {
    let response = await axios.patch(baseURL + "/api/platform-bank-details/" + bank_id, payload, config);
    return response.data.data;
}
export const delete_organisation_bank = async (bank_id) => {
    let response = await axios.delete(baseURL + "/api/organisation-bank-details/" + bank_id, config);
    return response.data.data;
}
export const delete_platform_bank = async (bank_id) => {
    let response = await axios.delete(baseURL + "/api/platform-bank-details/" + bank_id, config);
    return response.data.data;
}