import axios from "axios";  
const token = window.localStorage.getItem("token");
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
// const baseURL = secureLocalStorage.getItem("baseURL");

export const get_all_users = async () => {
    let response = await axios.get(baseURL + "/api/propertyappz-user", config);
    return response.data.data;
}
export const get_all_users_without_me = async () => {
    let response = await axios.get(baseURL + "/api/propertyappz-user-list-without-me", config);
    return response.data.data.users;
}
export const delete_user = async (user_id) => {
    let response = await axios.delete(baseURL + "/api/propertyappz-user/"+user_id, config);
    return response.data.data;
}
export const get_current_user = async (user_id) => {
    console.log(user_id);
    let response = await axios.get(baseURL + "/api/propertyappz-user/"+user_id, config);
    return response.data.data.user;
}