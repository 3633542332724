import React, { Component } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { Redirect, Link } from "react-router-dom";
import moment from "moment";
import { isEmpty, size } from "lodash";
import classnames from "classnames";
import {
    Button,
    Card,
    CardBody,
    CardTitle, CardText,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Row, InputGroup, Table, Modal, ModalBody, ModalHeader, UncontrolledTooltip,
    Nav,
    NavItem,
    NavLink,

    TabContent,
    TabPane,
} from "reactstrap";
import axios from "axios";
import Flatpickr from 'react-flatpickr'
import Select from "react-select"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { showToast } from "pages/UI/ui-toast";
import { get_lead_data, get_activity_logs_against_lead, delete_lead_avtivity_type, get_activity_logs_notes_against_lead } from "../api/leadsApi";
const token = window.localStorage.getItem("token");
import avatar from '../../../assets/images/avatar_01.png';
const config = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `${token}`
    }
};
// const baseURL = process.env.REACT_APP_API_URL;
import secureLocalStorage from "react-secure-storage";
const baseURL = secureLocalStorage.getItem("baseURL");
import DeleteModal from "../../../components/Common/DeleteModal";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import LeadLogs from "./LeadLogs";
class LeadView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validation: "",
            redirect: null,
            optionGroupLeadTypes: [],
            lead_type_ID: '',
            conversionDate: [new Date()],
            data: [],
            isActivityModalOpen: false,
            activityFormData: {
                startDateTime: new Date(),
                endDateTime: new Date(),
                description: ''
            },
            lead_activity_logs: [],
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            recordDeleteID: null,
            activeTab: "1",
            activeTab1: "5",
            activeTab2: "9",
            activeTab3: "13",
            verticalActiveTab: "1",
            verticalActiveTabWithIcon: "1",
            customActiveTab: "1",
            customIconActiveTab: "1",
            activeTabJustify: "5",
            col1: true,
            col2: false,
            col3: false,
            col5: true,
            col6: true,
            col7: true,
            col8: true,
            col9: true,
            col10: false,
            col11: false,
            lead_notes: [],
        };
        this.handleRecordClick = this.handleRecordClick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleRecordClicks = this.handleRecordClicks.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);

        this.t_col1 = this.t_col1.bind(this);
        this.t_col2 = this.t_col2.bind(this);
        this.t_col3 = this.t_col3.bind(this);
        this.t_col5 = this.t_col5.bind(this);
        this.t_col6 = this.t_col6.bind(this);
        this.t_col7 = this.t_col7.bind(this);
        this.t_col8 = this.t_col8.bind(this);
        this.t_col9 = this.t_col9.bind(this);
        this.t_col10 = this.t_col10.bind(this);
        this.t_col11 = this.t_col11.bind(this);

        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);

        this.toggleVertical = this.toggleVertical.bind(this);
        this.toggleVerticalIcon = this.toggleVerticalIcon.bind(this);
        this.toggleCustom = this.toggleCustom.bind(this);
        this.toggleIconCustom = this.toggleIconCustom.bind(this);
    }

    t_col1() {
        this.setState({
            col1: !this.state.col1,
            col2: false,
            col3: false,
        });
    }

    t_col2() {
        this.setState({
            col1: false,
            col2: !this.state.col2,
            col3: false,
        });
    }

    t_col3() {
        this.setState({
            col1: false,
            col2: false,
            col3: !this.state.col3,
        });
    }

    t_col5() {
        this.setState({ col5: !this.state.col5 });
    }

    t_col6() {
        this.setState({ col6: !this.state.col6 });
    }

    t_col7() {
        this.setState({ col7: !this.state.col7 });
    }

    t_col8() {
        this.setState({
            col6: !this.state.col6,
            col7: !this.state.col7,
        });
    }

    t_col9() {
        this.setState({
            col9: !this.state.col9,
            col10: false,
            col11: false,
        });
    }

    t_col10() {
        this.setState({
            col10: !this.state.col10,
            col9: false,
            col11: false,
        });
    }

    t_col11() {
        this.setState({
            col11: !this.state.col11,
            col9: false,
            col10: false,
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggle1(tab) {
        if (this.state.activeTab1 !== tab) {
            this.setState({
                activeTab1: tab,
            });
        }
    }

    toggle2(tab) {
        if (this.state.activeTab2 !== tab) {
            this.setState({
                activeTab2: tab,
            });
        }
    }

    toggle3(tab) {
        if (this.state.activeTab3 !== tab) {
            this.setState({
                activeTab3: tab,
            });
        }
    }

    toggleVertical(tab) {
        if (this.state.verticalActiveTab !== tab) {
            this.setState({
                verticalActiveTab: tab,
            });
        }
    }

    toggleVerticalIcon(tab) {
        if (this.state.verticalActiveTabWithIcon !== tab) {
            this.setState({
                verticalActiveTabWithIcon: tab,
            });
        }
    }

    toggleCustom(tab) {
        if (this.state.customActiveTab !== tab) {
            this.setState({
                customActiveTab: tab,
            });
        }
    }

    toggleIconCustom(tab) {
        if (this.state.customIconActiveTab !== tab) {
            this.setState({
                customIconActiveTab: tab,
            });
        }
    }
    async getOptionsLeadTypes() {
        const res = await axios.get(baseURL + "/api/log-type-listing", config);
        const data = res.data.data
        const options = data.map(d => ({
            "value": d.id,
            "label": d.name
        }))
        this.setState({ optionGroupLeadTypes: options })
    }
    componentDidMount() {
        this.getOptionsLeadTypes()
        Promise.all([get_lead_data(localStorage.getItem('current_lead_id'))]).then((values) => {
            this.setState({ data: values[0] })
        });
        Promise.all([get_activity_logs_against_lead(localStorage.getItem('current_lead_id'))]).then((values) => {
            this.setState({ lead_activity_logs: values[0] })
            this.setState({ listData: values[0] })
        });
        Promise.all([get_activity_logs_notes_against_lead(localStorage.getItem('current_lead_id'))]).then((values) => {
            this.setState({ lead_notes: values[0] })
        });

    }
    // Handle opening and closing of the activity log modal
    toggleActivityModal = () => {
        this.setState(prevState => ({
            isActivityModalOpen: !prevState.isActivityModalOpen
        }));
    }

    // Handle changes in the activity log form fields
    handleActivityFormChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            activityFormData: {
                ...prevState.activityFormData,
                [name]: value
            }
        }));
    }

    // Handle submission of the activity log form
    handleSubmitActivityForm = (e) => {
        e.preventDefault();
        var startDateTimeFormatted = '';
        var endDateTimeFormatted = '';
        var lead_id = localStorage.getItem('current_lead_id')
        if (Array.isArray(this.state.activityFormData.startDateTime) && this.state.activityFormData.startDateTime.length > 0) {
            startDateTimeFormatted = this.getDateFormateMethod(this.state.activityFormData.startDateTime[0]);
        } else {
            startDateTimeFormatted = new Date();
            startDateTimeFormatted = this.getDateFormateMethod(startDateTimeFormatted);
        }
        if (Array.isArray(this.state.activityFormData.endDateTime) && this.state.activityFormData.endDateTime.length > 0) {
            endDateTimeFormatted = this.getDateFormateMethod(this.state.activityFormData.endDateTime[0]);
        } else {
            endDateTimeFormatted = new Date();
            endDateTimeFormatted = this.getDateFormateMethod(endDateTimeFormatted);
        }
        axios.post(baseURL + "/api/lead-activity-logs", {
            "log_type_id": this.state.lead_type_ID?.value,
            "lead_id": parseInt(lead_id),
            "description": this.state.activityFormData.description,
            "start_date_time": startDateTimeFormatted,
            "end_date_time": endDateTimeFormatted,
        }, config)
            .then((response) => {
                showToast("Success", "The activity log added successfully.", "success");
                Promise.all([get_activity_logs_against_lead(localStorage.getItem('current_lead_id'))]).then((values) => {
                    this.setState({ listData: values[0] })
                });
                Promise.all([get_activity_logs_notes_against_lead(localStorage.getItem('current_lead_id'))]).then((values) => {
                    this.setState({ lead_notes: values[0] })
                });
                this.toggleActivityModal();
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message, "error")
            });
        // Close the modal after form submission
    }
    handleSubmitActivityEditForm = (e) => {
        e.preventDefault();
        var startDateTimeFormatted = '';
        var endDateTimeFormatted = '';
        var lead_id = localStorage.getItem('current_lead_id')
        if (Array.isArray(this.state.listdata.start_date_time) && this.state.listdata.start_date_time.length > 0) {
            startDateTimeFormatted = this.getDateFormateMethod(this.state.listdata.start_date_time[0]);
        } else {
            startDateTimeFormatted = this.state.listdata.start_date_time;
        }
        if (Array.isArray(this.state.listdata.end_date_time) && this.state.listdata.end_date_time.length > 0) {
            endDateTimeFormatted = this.getDateFormateMethod(this.state.listdata.end_date_time[0]);
        } else {
            endDateTimeFormatted = this.state.listdata.end_date_time;
        }
        axios.patch(baseURL + "/api/lead-activity-logs/" + this.state.listdata.id, {
            "log_type_id": this.state.listdata.log_type_id,
            "lead_id": parseInt(lead_id),
            "description": this.state.listdata.description,
            "start_date_time": startDateTimeFormatted,
            "end_date_time": endDateTimeFormatted,
        }, config)
            .then((response) => {
                showToast("Success", "The activity log edited successfully.", "success");
                Promise.all([get_activity_logs_against_lead(localStorage.getItem('current_lead_id'))]).then((values) => {
                    this.setState({ listData: values[0] })
                });
                Promise.all([get_activity_logs_notes_against_lead(localStorage.getItem('current_lead_id'))]).then((values) => {
                    this.setState({ lead_notes: values[0] })
                });
                this.toggle();
            })
            .catch((error) => {
                showToast("Failed", error.response.data.message, "error")
            });
        // Close the modal after form submission
    }
    handleSelectGroupLeadTypes = selectedGroupLeadTypes => {
        this.setState({ lead_type_ID: selectedGroupLeadTypes })
        this.setState({ selectedGroupLeadTypes })
    }

    getDateFormateMethod = (myDate) => {
        const today = myDate;
        const dd = today.getDate().toString().padStart(2, '0');
        const mm = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const yyyy = today.getFullYear();
        const HH = today.getHours().toString().padStart(2, '0');
        const ii = today.getMinutes().toString().padStart(2, '0');
        const SS = today.getSeconds().toString().padStart(2, '0');

        const formatted_date = `${dd}-${mm}-${yyyy} ${HH}:${ii}:${SS}`;
        return formatted_date;
    }
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    handleSelectedStatus = selectedStatus => {
        this.setState({ selectedStatus: selectedStatus })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ deleteModal: true });
    };

    handleDeletejob = () => {
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            Promise.all([delete_lead_avtivity_type(this.state.recordDeleteID)]).then((values) => {
                var toastTitle = "Success";
                var toastMessage = "The lead activity type has been deleted successfully.";
                var toastType = "success";
                this.showToast(toastTitle, toastMessage, toastType)
                Promise.all([get_activity_logs_against_lead(localStorage.getItem('current_lead_id'))]).then((values) => {
                    this.setState({ listData: values[0] });
                });
                Promise.all([get_activity_logs_notes_against_lead(localStorage.getItem('current_lead_id'))]).then((values) => {
                    this.setState({ lead_notes: values[0] })
                });

            }).catch((error) => {
                var toastTitle = "Failed";
                var toastMessage = "Log Type Not Deleted";
                var toastType = "error";
                this.showToast(toastTitle, toastMessage, toastType);
            });
            this.setState({ deleteModal: false });
        }
    };
    handleRecordClicks = () => {
        this.setState({ listdata: "", isEdit: false });
        this.toggle();
    };
    handleRecordClick = arg => {
        const listdata = arg;
        this.setState({
            listdata: {
                id: listdata.id,
                log_type_name: listdata.log_type_name,
                description: listdata.description,
                end_date_time: listdata.end_date_time,
                lead_id: listdata.lead_id,
                log_type_id: listdata.log_type_id,
                start_date_time: listdata.start_date_time
            },
            isEdit: true,
        });

        this.toggle();
    };
    render() {
        //meta title
        document.title = "Lead View";
        const { data, lead_activity_logs, isActivityModalOpen, activityFormData } = this.state;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const { selectedGroupLeadTypes } = this.state;
        const { isEdit, deleteModal } = this.state;

        const listdata = this.state.listdata;

        const columns = [
            {
                dataField: "log_type_name",
                text: "Log Type Name",
                sort: true,
            },
            {
                dataField: "start_date_time",
                text: "Start Date Time",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.start_date_time, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY hh:mm:ss A')}
                    </>
                ),
            },
            {
                dataField: "end_date_time",
                text: "End Date Time",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.end_date_time, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY hh:mm:ss A')}
                    </>
                ),
            },
            {
                dataField: "updated_by_name",
                text: "Updated By",
                sort: true,
            },
            {
                dataField: "updated_at",
                text: "Updated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>

                ),
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-info"
                                    onClick={() => this.handleRecordClick(listdata)}
                                >
                                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                    <UncontrolledTooltip placement="top" target="edittooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className="btn btn-sm btn-soft-danger"
                                    onClick={() => this.onClickDelete(listdata)}
                                >
                                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </li>
                        </ul>
                    </>
                ),
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            // totalSize: productsData.length, // replace later with size(customers),
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });

            }
        }
        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeletejob}
                    record={`lead activity type`}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <Container fluid={true}>
                        <Row>

                            <Col xl="12">
                                <Card className="mx-n4 mt-n4 bg-info-lighter">
                                    <CardBody>
                                        <div className="text-center mb-4">
                                            <img src={avatar} alt="" className="avatar-md rounded-circle mx-auto d-block" />
                                            <h5 className="mt-3 mb-1">{data.customer_first_name + " " + data.customer_last_name}</h5>
                                            {/* <p className="text-muted mb-3">UI/UX Designer</p> */}
                                            <div className="mx-auto">
                                                <span className="badge me-1 text-bg-info">{data.label}</span>
                                            </div>
                                        </div>
                                        <Button className="float-end" color="primary" onClick={this.toggleActivityModal}>Activity Log</Button>
                                    </CardBody>
                                </Card>
                                {/* Activity Log Modal */}
                                <Modal isOpen={isActivityModalOpen} toggle={this.toggleActivityModal}>
                                    <ModalHeader toggle={this.toggleActivityModal}>Activity Log</ModalHeader>
                                    <ModalBody>
                                        <form onSubmit={this.handleSubmitActivityForm}>
                                            <FormGroup>
                                                <div className="mb-3 select2-container">
                                                    <Label className="col-md-12 col-form-label" htmlFor="LeadActivityType">Lead Activity Type*</Label>
                                                    <div className="col-md-12">
                                                        <Select
                                                            value={selectedGroupLeadTypes}
                                                            onChange={this.handleSelectGroupLeadTypes}
                                                            options={this.state.optionGroupLeadTypes}
                                                            classNamePrefix="select2-selection"
                                                        />
                                                    </div>
                                                </div>
                                            </FormGroup>

                                            <div className="mb-3">
                                                <Label className="col-md-12 col-form-label" htmlFor="selectDate">Start Date*</Label>
                                                <div className="docs-datepicker col-md-12">
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Pick a start date"
                                                            value={activityFormData.startDateTime}
                                                            options={{
                                                                minDate: "today",
                                                                enableTime: true,
                                                                dateFormat: "d/m/Y H:i:S",
                                                            }}
                                                            onChange={date => {
                                                                this.setState(prevState => ({
                                                                    activityFormData: {
                                                                        ...prevState.activityFormData,
                                                                        startDateTime: date
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                disabled
                                                            >
                                                                <i
                                                                    className="fa fa-calendar"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </div>
                                                    </InputGroup>
                                                    <div className="docs-datepicker-container" />
                                                </div>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="col-md-12 col-form-label" htmlFor="selectDate">End Date*</Label>
                                                <div className="docs-datepicker col-md-12">
                                                    <InputGroup>
                                                        <Flatpickr
                                                            className="form-control d-block"
                                                            placeholder="Pick a end date"
                                                            value={activityFormData.endDateTime}
                                                            options={{
                                                                minDate: "today",
                                                                enableTime: true,
                                                                dateFormat: "d/m/Y H:i:S",
                                                            }}
                                                            onChange={date => {
                                                                this.setState(prevState => ({
                                                                    activityFormData: {
                                                                        ...prevState.activityFormData,
                                                                        endDateTime: date
                                                                    }
                                                                }));
                                                            }}
                                                        />
                                                        <div className="input-group-append">
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                disabled
                                                            >
                                                                <i
                                                                    className="fa fa-calendar"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </div>
                                                    </InputGroup>
                                                    <div className="docs-datepicker-container" />
                                                </div>
                                            </div>
                                            <FormGroup>
                                                <Label for="description">Description*</Label>
                                                <Input
                                                    type="textarea"
                                                    name="description"
                                                    id="description"
                                                    value={activityFormData.description}
                                                    onChange={this.handleActivityFormChange}
                                                />
                                            </FormGroup>
                                            <Button color="primary" type="submit">Submit</Button>
                                        </form>
                                    </ModalBody>
                                </Modal>

                            </Col>
                            <Col xl={12}>
                                <Card>
                                    <CardBody>
                                        <Nav tabs className="nav-tabs-custom nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: this.state.customActiveTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        this.toggleCustom("1");
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="fas fa-home"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block">Overview</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: this.state.customActiveTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        this.toggleCustom("2");
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="far fa-user"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block">Notes</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: this.state.customActiveTab === "3",
                                                    })}
                                                    onClick={() => {
                                                        this.toggleCustom("3");
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="far fa-user"></i>
                                                    </span>
                                                    <span className="d-none d-sm-block">Logs</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent
                                            activeTab={this.state.customActiveTab}
                                            className="p-3 text-muted"
                                        >
                                            <TabPane tabId="1">
                                                <Row>
                                                    <Col xl="12">
                                                        {/* <CardTitle>Lead Details</CardTitle> */}


                                                        <Table className="table-borderless">
                                                            <thead>
                                                                <tr>
                                                                    <th>Field</th>
                                                                    <th>Value</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>First Name</td>
                                                                    <td>{data.customer_first_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Last Name</td>
                                                                    <td>{data.customer_last_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mobile</td>
                                                                    <td>{data.customer_mobile}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Email</td>
                                                                    <td>{data.customer_email}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Campaign Name</td>
                                                                    <td>{data.campaign_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Sales Agent Name</td>
                                                                    <td>{data.sales_agent_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Lead Type Name</td>
                                                                    <td>{data.lead_type_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Lead Stage Name</td>
                                                                    <td>{data.lead_stage_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Lead Pipeline Name</td>
                                                                    <td>{data.lead_pipeline_name}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Label</td>
                                                                    <td>{data.label}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Score</td>
                                                                    <td>{data.score}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Amount</td>
                                                                    <td>{data.amount}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Conversion Date</td>
                                                                    <td>{moment(data.conversion_date).format('DD/MM/YYYY')}</td>
                                                                </tr>
                                                            </tbody>

                                                        </Table>

                                                    </Col>
                                                </Row>
                                                <Row className="mt-4 pt-4">
                                                    <Col xl="12">
                                                        {lead_activity_logs.length > 0 ? <>
                                                            <PaginationProvider
                                                                pagination={paginationFactory(pageOptions)}
                                                                keyField="id"
                                                                columns={columns}
                                                                data={this.state.listData}
                                                            >
                                                                {({ paginationProps, paginationTableProps }) => (
                                                                    <ToolkitProvider
                                                                        keyField="id"
                                                                        columns={columns}
                                                                        data={this.state.listData}
                                                                        search
                                                                    >
                                                                        {toolkitProps => (
                                                                            <React.Fragment>
                                                                                {/* <Row className="mb-2">
                                                                                    <Col md="4">
                                                                                        <div className="search-box me-2 mb-2 d-inline-block">
                                                                                            <div className="position-relative">
                                                                                                <SearchBar
                                                                                                    {...toolkitProps.searchProps}
                                                                                                />
                                                                                                <i className="bx bx-search-alt search-icon" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row> */}

                                                                                <Row>
                                                                                    <Col xl="12">
                                                                                        <div className="table-responsive">
                                                                                            <BootstrapTable
                                                                                                keyField={"id"}
                                                                                                responsive
                                                                                                bordered={false}
                                                                                                striped={false}
                                                                                                defaultSorted={defaultSorted}
                                                                                                // selectRow={selectRow}
                                                                                                rowEvents={rowEvents}
                                                                                                classes={
                                                                                                    "table align-middle table-nowrap"
                                                                                                }
                                                                                                headerWrapperClasses={"thead-light"}
                                                                                                {...toolkitProps.baseProps}
                                                                                                {...paginationTableProps}
                                                                                                ref={this.node}
                                                                                            />
                                                                                            <Modal
                                                                                                isOpen={this.state.modal}
                                                                                                className={this.props.className}
                                                                                            >
                                                                                                <ModalHeader toggle={this.toggle} tag="h4">
                                                                                                    {!!isEdit ? "Edit Lead Activity Type" : "Add Lead Activity Type"}
                                                                                                </ModalHeader>
                                                                                                <ModalBody>
                                                                                                    <Formik
                                                                                                        enableReinitialize={true}
                                                                                                    // initialValues={{
                                                                                                    //     id: (listdata && listdata.id) || '',
                                                                                                    //     name: (listdata && listdata.name) || '',
                                                                                                    // }}
                                                                                                    // validationSchema={Yup.object().shape({
                                                                                                    //     id: Yup.string().required("Please Enter Your Id"),
                                                                                                    //     name: Yup.string().required("Please Enter Your Name"),
                                                                                                    // })}
                                                                                                    >
                                                                                                        {({ errors, status, touched }) => (
                                                                                                            <Form onSubmit={this.handleSubmitActivityEditForm}>
                                                                                                                <Row>
                                                                                                                    <Col className="col-12">
                                                                                                                        <div className="mb-3">
                                                                                                                            <Label className="form-label">
                                                                                                                                Lead Activity Type Id
                                                                                                                            </Label>
                                                                                                                            <Field
                                                                                                                                name="id"
                                                                                                                                disabled
                                                                                                                                type="text"
                                                                                                                                value={listdata.id}
                                                                                                                                className={
                                                                                                                                    "form-control"
                                                                                                                                }
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                        <div className="mb-3">
                                                                                                                            <FormGroup>
                                                                                                                                <div className="mb-3 select2-container">
                                                                                                                                    <Label className="col-md-12 col-form-label" htmlFor="LeadActivityType">Lead Activity Type*</Label>
                                                                                                                                    <div className="col-md-12">
                                                                                                                                        <Select
                                                                                                                                            defaultValue={{ label: listdata.log_type_name, value: listdata.log_type_id }}
                                                                                                                                            // onChange={this.handleSelectGroupLeadTypes}
                                                                                                                                            onChange={selectedGroupTypes => {
                                                                                                                                                this.setState(prevState => ({
                                                                                                                                                    listdata: {
                                                                                                                                                        ...prevState.listdata,
                                                                                                                                                        log_type_id: selectedGroupTypes.value,
                                                                                                                                                        log_type_name: selectedGroupTypes.label,
                                                                                                                                                    }
                                                                                                                                                }));
                                                                                                                                            }}
                                                                                                                                            options={this.state.optionGroupLeadTypes}
                                                                                                                                            classNamePrefix="select2-selection"
                                                                                                                                        />
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </FormGroup>

                                                                                                                        </div>
                                                                                                                        <div className="mb-3">
                                                                                                                            <Label className="col-md-12 col-form-label" htmlFor="selectDate">Start Date*</Label>
                                                                                                                            <div className="docs-datepicker col-md-12">
                                                                                                                                <InputGroup>
                                                                                                                                    <Flatpickr
                                                                                                                                        className="form-control d-block"
                                                                                                                                        placeholder="Pick a start date"
                                                                                                                                        options={{
                                                                                                                                            enableTime: true,
                                                                                                                                            dateFormat: "d/m/Y H:i:S",
                                                                                                                                            defaultDate: listdata.start_date_time
                                                                                                                                        }}
                                                                                                                                        onChange={date => {
                                                                                                                                            this.setState(prevState => ({
                                                                                                                                                listdata: {
                                                                                                                                                    ...prevState.listdata,
                                                                                                                                                    start_date_time: date
                                                                                                                                                }
                                                                                                                                            }));
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                    <div className="input-group-append">
                                                                                                                                        <button
                                                                                                                                            type="button"
                                                                                                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                                                            disabled
                                                                                                                                        >
                                                                                                                                            <i
                                                                                                                                                className="fa fa-calendar"
                                                                                                                                                aria-hidden="true"
                                                                                                                                            />
                                                                                                                                        </button>
                                                                                                                                    </div>
                                                                                                                                </InputGroup>
                                                                                                                                <div className="docs-datepicker-container" />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="mb-3">
                                                                                                                            <Label className="col-md-12 col-form-label" htmlFor="selectDate">End Date*</Label>
                                                                                                                            <div className="docs-datepicker col-md-12">
                                                                                                                                <InputGroup>
                                                                                                                                    <Flatpickr
                                                                                                                                        className="form-control d-block"
                                                                                                                                        placeholder="Pick a end date"
                                                                                                                                        options={{
                                                                                                                                            enableTime: true,
                                                                                                                                            dateFormat: "d/m/Y H:i:S",
                                                                                                                                            defaultDate: listdata.end_date_time
                                                                                                                                        }}
                                                                                                                                        onChange={date => {
                                                                                                                                            this.setState(prevState => ({
                                                                                                                                                listdata: {
                                                                                                                                                    ...prevState.listdata,
                                                                                                                                                    end_date_time: date
                                                                                                                                                }
                                                                                                                                            }));
                                                                                                                                        }}
                                                                                                                                    />
                                                                                                                                    <div className="input-group-append">
                                                                                                                                        <button
                                                                                                                                            type="button"
                                                                                                                                            className="btn btn-outline-secondary docs-datepicker-trigger"
                                                                                                                                            disabled
                                                                                                                                        >
                                                                                                                                            <i
                                                                                                                                                className="fa fa-calendar"
                                                                                                                                                aria-hidden="true"
                                                                                                                                            />
                                                                                                                                        </button>
                                                                                                                                    </div>
                                                                                                                                </InputGroup>
                                                                                                                                <div className="docs-datepicker-container" />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <FormGroup>
                                                                                                                            <Label for="description">Description*</Label>
                                                                                                                            <Input
                                                                                                                                type="textarea"
                                                                                                                                name="description"
                                                                                                                                id="description"
                                                                                                                                defaultValue={listdata.description}
                                                                                                                                // onChange={this.handleActivityFormChange}
                                                                                                                                onChange={description => {
                                                                                                                                    this.setState(prevState => ({
                                                                                                                                        listdata: {
                                                                                                                                            ...prevState.listdata,
                                                                                                                                            description: description.target.value
                                                                                                                                        }
                                                                                                                                    }));
                                                                                                                                }}
                                                                                                                            />
                                                                                                                        </FormGroup>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                                <Row>
                                                                                                                    <Col>
                                                                                                                        <div className="text-end">
                                                                                                                            <button
                                                                                                                                type="submit"
                                                                                                                                className="btn btn-primary save-user"
                                                                                                                            >
                                                                                                                                Save
                                                                                                                            </button>
                                                                                                                        </div>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            </Form>
                                                                                                        )}
                                                                                                    </Formik>
                                                                                                </ModalBody>
                                                                                            </Modal>
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>

                                                                                <Row className="align-items-md-center mt-30">
                                                                                    <Col className="inner-custom-pagination d-flex">
                                                                                        <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                                            <SizePerPageDropdownStandalone
                                                                                                {...paginationProps}
                                                                                            />
                                                                                        </div>
                                                                                        <div className="text-md-right ms-auto">
                                                                                            <PaginationListStandalone
                                                                                                {...paginationProps}
                                                                                            />
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </ToolkitProvider>
                                                                )}
                                                            </PaginationProvider>
                                                        </> : <>No data found against lead!</>}
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <Row>
                                                    <div>
                                                        {this.state.lead_notes.map(note => (
                                                            <Col sm="12" key={note.id}>
                                                                <Card className="mb-3 border">
                                                                    <CardBody>
                                                                        <CardTitle tag="h6" className="mb-2 text-muted">
                                                                            Logged a note <span className="float-end">{moment(note.created_at).format('MMM DD, YYYY')}</span>
                                                                            <hr style={{
                                                                                color: '#d3d3d3',
                                                                                backgroundColor: '#d3d3d3',
                                                                                height: .5,
                                                                                borderColor: '#d3d3d3'
                                                                            }} />
                                                                        </CardTitle>
                                                                        <CardText>{note.description}</CardText>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        ))}
                                                    </div>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="3">
                                                <Row>
                                                    <div>
                                                        {/* {this.state.lead_notes.map(note => ( */}
                                                        <Col sm="12">
                                                            <LeadLogs />
                                                        </Col>
                                                        {/* ))} */}
                                                    </div>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment >
        );
    }
}
export default LeadView;