import React, { Component } from "react";
import { Row, Col, Card, CardBody, CardTitle, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import { isEmpty, size } from "lodash";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import DeleteModal from "../../../../components/Common/DeleteModal";
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import moment from "moment";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get_all_tenants, delete_tenant } from '../api/tenantApi';
import { get_user_permissions } from "helpers/custom_helper";
var propertyappzTenantView, propertyappzTenantDelete, propertyappzTenantEdit, propertyappzTenantCreate;
class ListTenants extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        this.state = {
            page: 1,
            sizePerPage: 10,
            listData: [],
            listdata: "",
            viewmodal: false,
            modal: false,
            isOpen: false,
            selectDate: false,
            deleteModal: false,
            recordDeleteID: null,
            redirect: null,
        };
        this.handleRecordClick = this.handleRecordClick.bind(this);
        this.handleRecordClickView = this.handleRecordClickView.bind(this);
        this.toggle = this.toggle.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }
    componentDidMount() {
        window.localStorage.removeItem("tenant_id");
        propertyappzTenantCreate = get_user_permissions('tenants-create')
        propertyappzTenantView = get_user_permissions('tenants-view')
        propertyappzTenantEdit = get_user_permissions('tenants-edit')
        propertyappzTenantDelete = get_user_permissions('tenants-delete')
        Promise.all([get_all_tenants()]).then((values) => {
            this.setState({ listData: values[0] });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { listData } = this.props;
        if (!isEmpty(listData) && size(prevProps.listData) !== size(listData)) {
            this.setState({ listData: {}, isEdit: false });
        }
    }
    onPaginationPageChange = page => {
        if (
            this.node &&
            this.node.current &&
            this.node.current.props &&
            this.node.current.props.pagination &&
            this.node.current.props.pagination.options
        ) {
            this.node.current.props.pagination.options.onPageChange(page);
        }
    };
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    toggleDeleteModal = () => {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal,
        }));
    };
    onClickDelete = listdata => {
        this.setState({ listdata: listdata });
        this.setState({ deleteModal: true });
    };

    handleDeletejob = () => {
        const { listdata } = this.state;
        if (listdata.id !== undefined) {
            Promise.all([delete_tenant(this.state.recordDeleteID)]).then((values) => {
                var toastTitle = "Success";
                var toastMessage = "The tenant has been deleted successfully.";
                var toastType = "success";
                this.showToast(toastTitle, toastMessage, toastType)
                Promise.all([get_all_tenants()]).then((values) => {
                    this.setState({ listData: values[0] });
                });

            }).catch((error) => {
                var toastTitle = "Failed";
                var toastMessage = "Tenant Not Deleted";
                var toastType = "error";
                this.showToast(toastTitle, toastMessage, toastType);
            });
            this.setState({ deleteModal: false });
        }
    };
    handleRecordClick = arg => {
        const listdata = arg;
        window.localStorage.setItem("tenant_id", listdata.id);
        this.setState({ redirect: "/propertyappz/tenants/edit" });
    };
    handleRecordClickView = arg => {
        const listdata = arg;
        window.localStorage.setItem("tenant_id", listdata.id);
        this.setState({ redirect: "/propertyappz/tenants/view" });
    };
    showToast(title, message, toastType) {
        //position class
        var positionClass = "toast-top-right";
        toastr.options = {
            positionClass: positionClass,
            timeOut: this.state.timeOut,
            extendedTimeOut: this.state.extendedTimeOut,
            showEasing: this.state.showEasing,
            hideEasing: this.state.hideEasing,
            showMethod: this.state.showMethod,
            hideMethod: this.state.hideMethod,
            showDuration: this.state.showDuration,
            hideDuration: this.state.hideDuration,
        };

        // setTimeout(() => toastr.success(`Settings updated `), 300)
        //Toaster Types
        if (toastType === "info") toastr.info(message, title);
        else if (toastType === "warning") toastr.warning(message, title);
        else if (toastType === "error") toastr.error(message, title);
        else toastr.success(message, title);
    }
    render() {

        const { deleteModal } = this.state;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        const listdata = this.state.listdata;

        const columns = [
            {
                dataField: "name",
                text: "Name",
                sort: true,
            },
            {
                dataField: "updated_name",
                text: "Updated By",
                sort: true,
            },
            {
                dataField: "updated_at",
                text: "Updated At",
                sort: true,
                formatter: (cellContent, listdata) => (
                    <>
                        {moment(listdata.updated_at).format('DD/MM/YYYY h:mma')}
                    </>
                ),
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, listdata) => (
                    <>
                        <ul className="list-unstyled hstack gap-1 mb-0">
                            {propertyappzTenantView ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => this.handleRecordClickView(listdata)}
                                    >
                                        <i className="mdi mdi-eye-outline" id="viewtooltip" />
                                        <UncontrolledTooltip placement="top" target="viewtooltip">
                                            View
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : null}
                            {propertyappzTenantEdit ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => this.handleRecordClick(listdata)}
                                    >
                                        <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                            Edit
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : null}
                            {propertyappzTenantDelete ? <>
                                <li>
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-danger"
                                        onClick={() => this.onClickDelete(listdata)}
                                    >
                                        <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                            Delete
                                        </UncontrolledTooltip>
                                    </Link>
                                </li>
                            </> : null}
                        </ul>
                    </>
                ),
            },

        ];

        const defaultSorted = [
            {
                dataField: "id",
                order: "asc",
            },
        ];

        const pageOptions = {
            sizePerPage: 10,
            // totalSize: productsData.length, // replace later with size(customers),
            custom: true,
        };

        // Select All Button operation
        const selectRow = {
            mode: "checkbox",
        };

        const { SearchBar } = Search;

        //meta title
        document.title = "List Tenants";
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.setState({ recordDeleteID: row.id });

            }
        }
        return (
            <React.Fragment>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={this.handleDeletejob}
                    record={`tenant`}
                    onCloseClick={() => this.setState({ deleteModal: false })}
                />
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Tenants" breadcrumbItem="List Tenants" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <CardBody className="border-bottom">
                                            <div className="d-flex align-items-center">
                                                <h5 className="mb-0 card-title flex-grow-1">List Tenants</h5>
                                                {propertyappzTenantCreate ? <>
                                                    <div className="flex-shrink-0">
                                                        <Link to="/propertyappz/tenants/add" className="btn btn-primary me-1">Add New Tenant</Link>
                                                    </div>
                                                </> : null}
                                            </div>
                                        </CardBody>
                                        <PaginationProvider
                                            pagination={paginationFactory(pageOptions)}
                                            keyField="id"
                                            columns={columns}
                                            data={this.state.listData}
                                        >
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider
                                                    keyField="id"
                                                    columns={columns}
                                                    data={this.state.listData}
                                                    search
                                                >
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField={"id"}
                                                                            responsive
                                                                            bordered={false}
                                                                            striped={false}
                                                                            defaultSorted={defaultSorted}
                                                                            selectRow={selectRow}
                                                                            rowEvents={rowEvents}
                                                                            classes={
                                                                                "table align-middle table-nowrap"
                                                                            }
                                                                            headerWrapperClasses={"thead-light"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            ref={this.node}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">Results Per Page&nbsp;&nbsp;
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )}
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ListTenants;