import PropTypes from "prop-types";
import React, { Component } from "react";
import { get_user_permissions } from "../../helpers/custom_helper"

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
var addProjectPath = '/projects/add?section=1';
var isAddProject = false;
var adminUserLoginFlag = true;
var projectEdit, projectView, projectCreate, projectDelete, projectLog;
var propertyEdit, propertyView, propertyCreate, propertyDelete, propertyLog;
var paymentPlanEdit, paymentPlanView, paymentPlanCreate, paymentPlanDelete, paymentPlanLog;
var customerEdit, customerView, customerCreate, customerDelete, customerLog;
var agentEdit, agentView, agentCreate, agentDelete, agentLog;
var roleEdit, roleView, roleCreate, roleDelete;
var userEdit, userView, userCreate, userDelete;
var settingView;
var saleEdit, saleView, saleCreate, saleDelete, saleLog;


var platformRoleEdit, platformRoleView, platformRoleCreate, platformRoleDelete;
var platformUserEdit, platformUserView, platformUserCreate, platformUserDelete;
var projectTypesEdit, projectTypesView, projectTypesCreate, projectTypesDelete;
var propertyTypesEdit, propertyTypesView, propertyTypesCreate, propertyTypesDelete;
var propertyFeaturesEdit, propertyFeaturesView, propertyFeaturesCreate, propertyFeaturesDelete;
var currencyEdit, currencyView, currencyCreate, currencyDelete;
var documentTypeEdit, documentTypeView, documentTypeCreate, documentTypeDelete;
var countryEdit, countryView, countryCreate, countryDelete;
var citiesCreate, citiesView, citiesEdit, citiesDelete, platformCitiesCreate, platformCitiesView, platformCitiesEdit, platformCitiesDelete;
var paymentTypeEdit, paymentTypeView, paymentTypeCreate, paymentTypeDelete;
var integrationEdit, integrationView, integrationCreate, integrationDelete;
var platformOrgEdit, platformOrgView, platformOrgCreate, platformOrgDelete;
var orgOrgEdit, orgOrgView;
// not used for now.
// var reports;
var identityDocumentView, salesDocumentView, financialsDocumentView;
var documentTemplateView, documentTemplateEdit, documentTemplateDelete, documentTemplateCreate, documentTemplatesLogs;
var emailTemplateView, emailTemplateEdit, emailTemplateDelete, emailTemplateCreate;
var documentsLogsView;
var salesReportView, paymentReportsView, overdueReportsView, projectReportsView;
var propertyappzRolesView, propertyappzRolesDelete, propertyappzRolesEdit, propertyappzRolesCreate;
var propertyappzUsersView, propertyappzUsersDelete, propertyappzUsersEdit, propertyappzUsersCreate;
var propertyappzTenantView, propertyappzTenantDelete, propertyappzTenantEdit, propertyappzTenantCreate;
var campaignsCreate, campaignsView, campaignsEdit, campaignsDelete;
var leadsCreate, leadsView, leadsEdit, leadsDelete;
var leadsSettingsView;
var logTypesCreate, logTypesView, logTypesEdit, logTypesDelete, leadTypesCreate, leadTypesView, leadTypesEdit, leadTypesDelete, campaignTypesCreate, campaignTypesView, campaignTypesEdit, campaignTypesDelete,
  leadStagesCreate, leadStagesView, leadStagesEdit, leadStagesDelete, leadPipelinesCreate, leadPipelinesView, leadPipelinesEdit, leadPipelinesDelete;

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    /* ---------- Get Project Permissions Variables ---------- */
    projectEdit = get_user_permissions('projects-edit');
    projectView = get_user_permissions('projects-view');
    projectCreate = get_user_permissions('projects-create');
    projectDelete = get_user_permissions('projects-delete');
    projectLog = get_user_permissions('project-logs-view');
    /* ---------- Get Property Permissions Variables ---------- */
    propertyEdit = get_user_permissions('properties-edit');
    propertyView = get_user_permissions('properties-view');
    propertyCreate = get_user_permissions('properties-create');
    propertyDelete = get_user_permissions('properties-delete');
    propertyLog = get_user_permissions('property-logs-view');
    /* ---------- Get Payment Plan Permissions Variables ---------- */
    paymentPlanEdit = get_user_permissions('payment-plan-edit');
    paymentPlanView = get_user_permissions('payment-plan-view');
    paymentPlanCreate = get_user_permissions('payment-plan-create');
    paymentPlanDelete = get_user_permissions('payment-plan-delete');
    paymentPlanLog = get_user_permissions('payment-plan-logs-view');
    /* ---------- Get Customers & Nominees Permissions Variables ---------- */
    customerEdit = get_user_permissions('customers-edit');
    customerView = get_user_permissions('customers-view');
    customerCreate = get_user_permissions('customers-create');
    customerDelete = get_user_permissions('customers-delete');
    customerLog = get_user_permissions('customer-logs-view');
    /* ---------- Get Sales Agents Permissions Variables ---------- */
    agentEdit = get_user_permissions('sale-agents-edit');
    agentView = get_user_permissions('sale-agents-view');
    agentCreate = get_user_permissions('sale-agents-create');
    agentDelete = get_user_permissions('sale-agents-delete');
    agentLog = get_user_permissions('sale-agent-logs-view');

    roleEdit = get_user_permissions('orginasation-roles-edit');
    roleView = get_user_permissions('orginasation-roles-view');
    roleCreate = get_user_permissions('orginasation-roles-create');
    roleDelete = get_user_permissions('orginasation-roles-delete');
    userEdit = get_user_permissions('users-edit');
    userView = get_user_permissions('users-view');
    userCreate = get_user_permissions('users-create');
    userDelete = get_user_permissions('users-delete');
    settingView = get_user_permissions('settings-view');
    /* ---------- Get Sales Permissions Variables ---------- */
    saleEdit = get_user_permissions('sales-edit');
    saleView = get_user_permissions('sales-view');
    saleCreate = get_user_permissions('sales-create');
    saleDelete = get_user_permissions('sales-delete');
    saleLog = get_user_permissions('sale-logs-view');

    platformRoleEdit = get_user_permissions('roles-edit');
    platformRoleView = get_user_permissions('roles-view');
    platformRoleCreate = get_user_permissions('roles-create');
    platformRoleDelete = get_user_permissions('roles-delete');

    platformUserCreate = get_user_permissions('platform-users-create');
    platformUserDelete = get_user_permissions('platform-users-delete');
    platformUserEdit = get_user_permissions('platform-users-edit');
    platformUserView = get_user_permissions('platform-users-view');

    projectTypesCreate = get_user_permissions('project-types-create');
    projectTypesDelete = get_user_permissions('project-types-delete');
    projectTypesEdit = get_user_permissions('project-types-edit');
    projectTypesView = get_user_permissions('project-types-view');

    propertyTypesCreate = get_user_permissions('property-types-create');
    propertyTypesDelete = get_user_permissions('property-types-delete');
    propertyTypesEdit = get_user_permissions('property-types-edit');
    propertyTypesView = get_user_permissions('property-types-view');

    propertyFeaturesCreate = get_user_permissions('property-features-create');
    propertyFeaturesDelete = get_user_permissions('property-features-delete');
    propertyFeaturesEdit = get_user_permissions('property-features-edit');
    propertyFeaturesView = get_user_permissions('property-features-view');

    currencyCreate = get_user_permissions('currencies-create');
    currencyDelete = get_user_permissions('currencies-delete');
    currencyEdit = get_user_permissions('currencies-edit');
    currencyView = get_user_permissions('currencies-view');

    documentTypeCreate = get_user_permissions('document-type-create');
    documentTypeDelete = get_user_permissions('document-type-delete');
    documentTypeEdit = get_user_permissions('document-type-edit');
    documentTypeView = get_user_permissions('document-type-view');

    countryCreate = get_user_permissions('countries-create');
    countryDelete = get_user_permissions('countries-delete');
    countryEdit = get_user_permissions('countries-edit');
    countryView = get_user_permissions('countries-view');

    citiesCreate = get_user_permissions('cities-create');
    citiesView = get_user_permissions('cities-view');
    citiesEdit = get_user_permissions('cities-edit');
    citiesDelete = get_user_permissions('cities-delete');
    platformCitiesCreate = get_user_permissions('platform-city-create');
    platformCitiesView = get_user_permissions('platform-city-view');
    platformCitiesEdit = get_user_permissions('platform-city-edit');
    platformCitiesDelete = get_user_permissions('platform-city-delete');

    paymentTypeCreate = get_user_permissions('payment-type-create');
    paymentTypeDelete = get_user_permissions('payment-type-delete');
    paymentTypeEdit = get_user_permissions('payment-type-edit');
    paymentTypeView = get_user_permissions('payment-type-view');

    integrationCreate = get_user_permissions('integrations-create');
    integrationDelete = get_user_permissions('integrations-delete');
    integrationEdit = get_user_permissions('integrations-edit');
    integrationView = get_user_permissions('integrations-view');

    //platformOrgCreate = get_user_permissions('organisation-setup-create');
    //platformOrgDelete = get_user_permissions('organisation-setup-delete');
    platformOrgEdit = get_user_permissions('organisation-setup-edit');
    platformOrgView = get_user_permissions('organisation-setup-view');

    orgOrgEdit = get_user_permissions('organisation-edit');
    orgOrgView = get_user_permissions('organisation-view');

    /* ---------- Get Documents Permissions Variables ---------- */
    identityDocumentView = get_user_permissions('identity-documents-view');
    salesDocumentView = get_user_permissions('sale-documents-view');
    financialsDocumentView = get_user_permissions('payment-documents-view');
    documentsLogsView = get_user_permissions('documents-logs-view');

    /* ---------- Get Template Documents Permissions Variables ---------- */
    documentTemplateEdit = get_user_permissions('document-templates-edit');
    documentTemplateView = get_user_permissions('document-templates-view');
    documentTemplateCreate = get_user_permissions('document-templates-create');
    documentTemplateDelete = get_user_permissions('document-templates-delete');
    documentTemplatesLogs = get_user_permissions('document-templates-logs-view');

    /* ---------- Get Template Email Permissions Variables ---------- */
    emailTemplateEdit = get_user_permissions('email-templates-edit');
    emailTemplateView = get_user_permissions('email-templates-view');
    emailTemplateCreate = get_user_permissions('email-templates-create');
    emailTemplateDelete = get_user_permissions('email-templates-delete');

    /* ---------- Get Reports Variables ---------- */
    salesReportView = get_user_permissions('sale-reports-view')
    paymentReportsView = get_user_permissions('payment-reports-view')
    overdueReportsView = get_user_permissions('overdue-reports-view')
    projectReportsView = get_user_permissions('project-reports-view')

    /* ---------- Get PropertyAppz Permissions ---------- */
    propertyappzUsersCreate = get_user_permissions('propertyAppz-users-create')
    propertyappzUsersView = get_user_permissions('propertyAppz-users-view')
    propertyappzUsersEdit = get_user_permissions('propertyAppz-users-edit')
    propertyappzUsersDelete = get_user_permissions('propertyAppz-users-delete')
    propertyappzRolesCreate = get_user_permissions('roles-create')
    propertyappzRolesView = get_user_permissions('roles-view')
    propertyappzRolesEdit = get_user_permissions('roles-edit')
    propertyappzRolesDelete = get_user_permissions('roles-delete')
    propertyappzTenantCreate = get_user_permissions('tenants-create')
    propertyappzTenantView = get_user_permissions('tenants-view')
    propertyappzTenantEdit = get_user_permissions('tenants-edit')
    propertyappzTenantDelete = get_user_permissions('tenants-delete')

    /* ---------- Get Leads Module Permissions ---------- */
    campaignsCreate = get_user_permissions('campaigns-create')
    campaignsView = get_user_permissions('campaigns-view')
    campaignsEdit = get_user_permissions('campaigns-edit')
    campaignsDelete = get_user_permissions('campaigns-delete')
    leadsCreate = get_user_permissions('leads-create')
    leadsView = get_user_permissions('leads-view')
    leadsEdit = get_user_permissions('leads-edit')
    leadsDelete = get_user_permissions('leads-delete')
    leadsSettingsView = get_user_permissions('lead-settings-view')

    /* ---------- Get Leads Module Catalogue Permissions ---------- */
    logTypesCreate = get_user_permissions('log-types-create')
    logTypesView = get_user_permissions('log-types-view')
    logTypesEdit = get_user_permissions('log-types-edit')
    logTypesDelete = get_user_permissions('log-types-delete')
    leadTypesCreate = get_user_permissions('lead-types-create')
    leadTypesView = get_user_permissions('lead-types-view')
    leadTypesEdit = get_user_permissions('lead-types-edit')
    leadTypesDelete = get_user_permissions('lead-types-delete')
    campaignTypesCreate = get_user_permissions('campaign-types-create')
    campaignTypesView = get_user_permissions('campaign-types-view')
    campaignTypesEdit = get_user_permissions('campaign-types-edit')
    campaignTypesDelete = get_user_permissions('campaign-types-delete')
    leadStagesCreate = get_user_permissions('lead-stages-create')
    leadStagesView = get_user_permissions('lead-stages-view')
    leadStagesEdit = get_user_permissions('lead-stages-edit')
    leadStagesDelete = get_user_permissions('lead-stages-delete')
    leadPipelinesCreate = get_user_permissions('lead-pipelines-create')
    leadPipelinesView = get_user_permissions('lead-pipelines-view')
    leadPipelinesEdit = get_user_permissions('lead-pipelines-edit')
    leadPipelinesDelete = get_user_permissions('lead-pipelines-delete')
    // not used for now
    // reports = get_user_permissions('sale-reports-view');

    // platformUserLoginFlag = localStorage.getItem("platformUserLoginFlag");
    var queryParam = window.location.search;
    if (queryParam.includes('section')) {
      isAddProject = true;
      // addProjectPath = window.location.pathname+queryParam;
    }
    else {
      isAddProject = false;
    }
    this.initMenu();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">

              {localStorage.getItem("adminUserLoginFlag") ? <>
                {/* <li className="menu-title">{this.props.t("Menu")}</li> */}
                <li className="menu-title">{this.props.t("Property Appz")}</li>
                {/*
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-home-circle" />
                    <span className="badge rounded-pill bg-info float-end">
                      04
                    </span>
                    <span>{this.props.t("Dashboards")}</span>
                  </Link> 
                </li>
                */}
                {platformRoleCreate || platformRoleDelete || platformRoleView || platformRoleEdit ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-lock" />
                      {/* <span className="badge rounded-pill bg-info float-end">
                    04
                  </span> */}
                      <span>{this.props.t("Roles")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {/* <li>
                    <Link to="/dashboard">{this.props.t("Default")}</Link>
                  </li>
                  <li>
                    <Link to="/dashboard-saas">{this.props.t("Saas")}</Link>
                  </li>
                  <li>
                    <Link to="/dashboard-crypto">{this.props.t("Crypto")}</Link>
                  </li> */}
                      {platformRoleCreate ? <>
                        <li>
                          {/* <Link to="/dashboard-blog">{this.props.t("Blog")}</Link> */}
                          <Link to="/platformroles/add">{this.props.t("Add Role")}</Link>
                        </li>
                      </> : <></>}
                      <li>
                        <Link to="/platformroles/list">

                          {this.props.t("List Roles")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </> : <></>}
                {platformUserCreate || platformUserDelete || platformUserView || platformUserEdit ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-user" />
                      <span>{this.props.t("Users")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {platformUserCreate ? <>
                        <li>
                          <Link to="/users/add">{this.props.t("Add User")}</Link>
                        </li>
                      </> : <></>}
                      <li>
                        <Link to="/users/list">

                          {this.props.t("List Users")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </> : <></>}
                <li>
                  <Link to="/#" className="has-arrow">
                    <i className="bx bx-book-open" />
                    <span>{this.props.t("Catalogue")}</span>
                  </Link>
                  <ul className="sub-menu mm-collapse">
                    {projectTypesCreate || projectTypesDelete || projectTypesView || projectTypesEdit ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Project Types")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {projectTypesCreate ? <>
                            <li>
                              <Link to="/catalogue/project-type/add">
                                {this.props.t("Add Project Types")}
                              </Link>
                            </li>
                          </> : <></>}
                          <li>
                            <Link to="/catalogue/project-type/list">
                              {this.props.t("List Project Types")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </> : <></>}
                    {propertyTypesCreate || propertyTypesDelete || propertyTypesView || propertyTypesEdit ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Property Types")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {propertyTypesCreate ? <>
                            <li>
                              <Link to="/catalogue/property-type/add">
                                {this.props.t("Add Property Types")}
                              </Link>
                            </li>
                          </> : <></>}
                          <li>
                            <Link to="/catalogue/property-type/list">
                              {this.props.t("List Property Types")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </> : <></>}
                    {propertyFeaturesCreate || propertyFeaturesDelete || propertyFeaturesView || propertyFeaturesEdit ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Property Features")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {propertyFeaturesCreate ? <>
                            <li>
                              <Link to="/catalogue/property-feature/add">
                                {this.props.t("Add Property Features")}
                              </Link>
                            </li>
                          </> : <></>}
                          <li>
                            <Link to="/catalogue/property-feature/list">
                              {this.props.t("List Property Features")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </> : <></>}
                    {currencyCreate || currencyDelete || currencyView || currencyEdit ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Currencies")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {currencyCreate ? <>
                            <li>
                              <Link to="/catalogue/currencies/add">
                                {this.props.t("Add Currency")}
                              </Link>
                            </li>
                          </> : <></>}
                          <li>
                            <Link to="/catalogue/currencies/list">
                              {this.props.t("List Currencies")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </> : <></>}
                    {/* Bank Details Open Menu */}
                    {1 ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Banks")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {1 ? <>
                            <li>
                              <Link to="/organisation/bank/add">
                                {this.props.t("Add Bank")}
                              </Link>
                            </li>
                          </> : <></>}
                          <li>
                            <Link to="/organisation/bank/list">
                              {this.props.t("List Banks")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </> : <></>}
                    {documentTypeCreate || documentTypeDelete || documentTypeView || documentTypeEdit ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Document Types")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {/* {documentTypeCreate ? <>
                            <li>
                              <Link to="/catalogue/document-types/add">
                                {this.props.t("Add Document Type")}
                              </Link>
                            </li>
                          </> : <></>} */}
                          <li>
                            <Link to="/catalogue/document-types/list">
                              {this.props.t("List Document Types")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </> : <></>}
                    {countryCreate || countryDelete || countryView || countryEdit ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Countries")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {countryCreate ? <>
                            <li>
                              <Link to="/catalogue/country/add">
                                {this.props.t("Add Country")}
                              </Link>
                            </li>
                          </> : <></>}
                          <li>
                            <Link to="/catalogue/country/list">
                              {this.props.t("List Countries")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </> : <></>}
                    {platformCitiesCreate || platformCitiesDelete || platformCitiesView || platformCitiesEdit ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Cities")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {platformCitiesCreate ? <>
                            <li>
                              <Link to="/catalogue/cities/add">
                                {this.props.t("Add City")}
                              </Link>
                            </li>
                          </> : <></>}
                          {platformCitiesView ? <>
                            <li>
                              <Link to="/catalogue/cities/list">
                                {this.props.t("List Cities")}{" "}
                              </Link>
                            </li>
                          </> : <></>}
                        </ul>
                      </li>
                    </> : <></>}
                    {/*
                  <li>
                    <Link to="/#" className="has-arrow">
                      <span key="t-email-templates">
                        {this.props.t("Cities")}
                      </span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/catalogue/cities/add">
                          {this.props.t("Add City")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/catalogue/cities/list">
                          {this.props.t("List Cities")}{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  */}
                    {paymentTypeCreate || paymentTypeDelete || paymentTypeView || paymentTypeEdit ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Payment Types")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {paymentTypeCreate ? <>
                            <li>
                              <Link to="/catalogue/payment-type/add">
                                {this.props.t("Add Payment Type")}
                              </Link>
                            </li>
                          </> : <></>}
                          <li>
                            <Link to="/catalogue/payment-type/list">
                              {this.props.t("List Payment Types")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </> : <></>}
                    {logTypesCreate || logTypesView || logTypesEdit || logTypesDelete ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Log Types")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {logTypesCreate ? <>
                            <li>
                              <Link to="/catalogue/log-type/add">
                                {this.props.t("Add Log Type")}
                              </Link>
                            </li>
                          </> : <></>}
                          {logTypesView ? <>
                            <li>
                              <Link to="/catalogue/log-type/list">
                                {this.props.t("List Log Types")}{" "}
                              </Link>
                            </li>
                          </> : <></>}
                        </ul>
                      </li>
                    </> : <></>}
                    {leadTypesCreate || leadTypesView || leadTypesEdit || leadTypesDelete ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Lead Types")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {leadTypesCreate ? <>
                            <li>
                              <Link to="/catalogue/lead-type/add">
                                {this.props.t("Add Lead Type")}
                              </Link>
                            </li>
                          </> : <></>}
                          {leadTypesView ? <>
                            <li>
                              <Link to="/catalogue/lead-type/list">
                                {this.props.t("List Lead Types")}{" "}
                              </Link>
                            </li>
                          </> : <></>}
                        </ul>
                      </li>
                    </> : <></>}
                    {campaignTypesCreate || campaignTypesView || campaignTypesEdit || campaignTypesDelete ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">{this.props.t("Campaign Types")}</span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {campaignTypesCreate ? <>
                            <li>
                              <Link to="/catalogue/campaign-type/add">
                                {this.props.t("Add Campaign Type")}
                              </Link>
                            </li>
                          </> : <></>}
                          {campaignTypesView ? <>
                            <li>
                              <Link to="/catalogue/campaign-type/list">
                                {this.props.t("List Campaign Types")}{" "}
                              </Link>
                            </li>
                          </> : <></>}
                        </ul>
                      </li>
                    </> : <></>}
                    {leadStagesCreate || leadStagesView || leadStagesEdit || leadStagesDelete ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">{this.props.t("Lead Stages")}</span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {leadStagesCreate ? <>
                            <li>
                              <Link to="/catalogue/lead-stage/add">
                                {this.props.t("Add Lead Stage")}
                              </Link>
                            </li>
                          </> : <></>}
                          {leadStagesView ? <>
                            <li>
                              <Link to="/catalogue/lead-stage/list">
                                {this.props.t("List Lead Stages")}{" "}
                              </Link>
                            </li>
                          </> : <></>}
                        </ul>
                      </li>
                    </> : <></>}
                    {leadPipelinesCreate || leadPipelinesView || leadPipelinesEdit || leadPipelinesDelete ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">{this.props.t("Lead Pipelines")}</span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {leadPipelinesCreate ? <>
                            <li>
                              <Link to="/catalogue/lead-pipeline/add">
                                {this.props.t("Add Lead Pipeline")}
                              </Link>
                            </li>
                          </> : <></>}
                          {leadPipelinesView ? <>
                            <li>
                              <Link to="/catalogue/lead-pipeline/list">
                                {this.props.t("List Lead Pipelines")}{" "}
                              </Link>
                            </li>
                          </> : <></>}
                        </ul>
                      </li>
                    </> : <></>}
                  </ul>
                </li>
                {platformOrgView || platformOrgEdit ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-network-chart" />
                      <span>{this.props.t("Organisation Setup")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      <li>
                        <Link to="/organisation-setup/setup">

                          {this.props.t("Setup Organisation")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </> : <></>}
                {integrationCreate || integrationDelete || integrationView || integrationEdit ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-cog" />
                      <span>{this.props.t("Integrations")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {integrationCreate ? <>
                        <li>
                          <Link to="/integrations/add">

                            {this.props.t("Add Integration")}
                          </Link>
                        </li>
                      </> : <></>}
                      <li>
                        <Link to="/integrations/list">

                          {this.props.t("List Integrations")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </> : <></>}
              </> : <>
                <li className="menu-title">
                  {localStorage.getItem('propertyAppzLoginFlag') ? (
                    <>{this.props.t("Property Appz Admin")}</>
                  ) : (
                    <>{this.props.t("Organisation")}</>
                  )}
                </li>
                {isAddProject ?
                  <>
                    {(projectCreate || projectView || projectDelete || projectEdit) ? <>
                      <li className="mm-active">
                        <Link to="/#" className="has-arrow">
                          <i className="bx bx-building-house" />
                          <span>{this.props.t("Projects")}</span>
                        </Link>
                        <ul className="sub-menu mm-collapse">
                          {projectCreate ? <>
                            <li className="mm-active">
                              <Link className="active" to={addProjectPath}>{this.props.t("Add Project")}</Link>
                            </li>
                          </> : <></>}
                          {projectView ? <>
                            <li>
                              <Link to="/projects/list">
                                {this.props.t("List Projects")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/deleted/projects/list">
                                {this.props.t("Deleted Projects")}
                              </Link>
                            </li>
                          </> : <></>}
                          {projectView ? <>
                            {projectLog ? <>
                              <li>
                                <Link to="/all/projects/logs">
                                  {this.props.t("Logs")}
                                </Link>
                              </li>
                            </> : <></>}
                          </> : <></>}
                        </ul>
                      </li>
                    </> : <></>}
                  </>

                  :
                  <>
                    {(projectCreate || projectView || projectDelete || projectEdit) ? <>
                      <li>
                        <Link to="/#" className="has-arrow">
                          <i className="bx bx-building-house" />
                          <span>{this.props.t("Projects")}</span>
                        </Link>
                        <ul className="sub-menu mm-collapse">
                          {projectCreate ? <>
                            <li>
                              <Link to={addProjectPath}>{this.props.t("Add Project")}</Link>
                            </li>
                          </> : <></>}
                          {projectView ? <>
                            <li>
                              <Link to="/projects/list">
                                {this.props.t("List Projects")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/deleted/projects/list">
                                {this.props.t("Deleted Projects")}
                              </Link>
                            </li>
                          </> : <></>}
                          {projectView ? <>
                            {projectLog ? <>
                              <li>
                                <Link to="/all/projects/logs">
                                  {this.props.t("Logs")}
                                </Link>
                              </li>
                            </> : <></>}
                          </> : <></>}
                        </ul>
                      </li>
                    </> : <></>}
                  </>
                }

                {propertyCreate || propertyDelete || propertyView || propertyEdit ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-home" />
                      <span>{this.props.t("Properties")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {propertyCreate ? <>
                        <li>
                          <Link to="/properties/add">{this.props.t("Add Property")}</Link>
                        </li>
                      </> : <></>}
                      {propertyView ? <>
                        <li>
                          <Link to="/properties/list">
                            {this.props.t("List Properties")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/deleted/properties/list">
                            {this.props.t("Deleted Properties")}
                          </Link>
                        </li>
                      </> : <></>}
                      {propertyView ? <>
                        {propertyLog ? <>
                          <li>
                            <Link to="/all/properties/logs">
                              {this.props.t("Logs")}
                            </Link>
                          </li>
                        </> : <></>}
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {paymentPlanCreate || paymentPlanDelete || paymentPlanView || paymentPlanEdit ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-home" />
                      <span>{this.props.t("Payment Plan")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {paymentPlanCreate ? <>
                        <li>
                          <Link to="/payment-plan/add">{this.props.t("Add Payment Plan")}</Link>
                        </li>
                      </> : <></>}
                      {paymentPlanView ? <>
                        <li>
                          <Link to="/payment-plan/list">
                            {this.props.t("List Payment Plans")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/deleted/payment-plans/list">
                            {this.props.t("Deleted Payment Plans")}
                          </Link>
                        </li>
                      </> : <></>}
                      {paymentPlanView ? <>
                        {paymentPlanLog ? <>
                          <li>
                            <Link to="/all/payment-plan/log">
                              {this.props.t("Logs")}
                            </Link>
                          </li>
                        </> : <></>}
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {customerCreate || customerDelete || customerView || customerEdit ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-user" />
                      <span>{this.props.t("Customers")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {customerCreate ? <>
                        <li>
                          <Link to="/customers/add">{this.props.t("Add Customer")}</Link>
                        </li>
                      </> : <></>}
                      {customerView ? <>
                        <li>
                          <Link to="/customers/list">
                            {this.props.t("List Customers")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/deleted/customers/list">
                            {this.props.t("Deleted Customers")}
                          </Link>
                        </li>
                      </> : <></>}
                      {customerView ? <>
                        {customerLog ? <>
                          <li>
                            <Link to="/customers/logs">
                              {this.props.t("Logs")}
                            </Link>
                          </li>
                        </> : <></>}
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {agentCreate || agentDelete || agentView || agentEdit ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-user" />
                      <span>{this.props.t("Sales Agents")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {agentCreate ? <>
                        <li>
                          <Link to="/salesagents/add">{this.props.t("Add Sales Agent")}</Link>
                        </li>
                      </> : <></>}
                      {agentView ? <>
                        <li>
                          <Link to="/salesagents/list">{this.props.t("List Sales Agents")}</Link>
                        </li>
                        <li>
                          <Link to="/deleted/salesagents/list">{this.props.t("Deleted Sales Agents")}</Link>
                        </li>
                      </> : <></>}
                      {agentView ? <>
                        {agentLog ? <>
                          <li>
                            <Link to="/all/salesagents/logs">{this.props.t("Logs")}</Link>
                          </li>
                        </> : <></>}
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {saleCreate || saleDelete || saleView || saleEdit ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-pound" />
                      <span>{this.props.t("Sales")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {saleCreate ? <>
                        <li>
                          <Link to="/sales/add">{this.props.t("Add Sale")}</Link>
                        </li>
                      </> : <></>}
                      {saleView ? <>
                        <li>
                          <Link to="/sales/list">

                            {this.props.t("List Sales")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/cancelled/sales/list">
                            {this.props.t("Cancelled Sales")}
                          </Link>
                        </li>
                      </> : <></>}
                      {saleView ? <>
                        {saleLog ? <>
                          <li>
                            <Link to="/all/sales/logs">
                              {this.props.t("Logs")}
                            </Link>
                          </li>
                        </> : <></>}
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {identityDocumentView || salesDocumentView || financialsDocumentView ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-file" />
                      <span>{this.props.t("Documents")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {identityDocumentView ? <>
                        <li>
                          <Link to="/#" className="has-arrow">{this.props.t("Identity Documents")}</Link>
                          <ul className="sub-menu mm-collapse">
                            {identityDocumentView ? <>
                              <li>
                                <Link to="/documents/identity">{this.props.t("Identity Documents")}</Link>
                              </li>
                            </> : <></>}
                            {identityDocumentView ? <>
                              {documentsLogsView ? <>
                                <li>
                                  <Link to="/all/documents/identity/logs">{this.props.t("Logs")}</Link>
                                </li>
                              </> : <></>}
                            </> : <></>}
                          </ul>
                        </li>
                      </> : <></>}
                      {salesDocumentView ? <>
                        <li>
                          <Link to="/#" className="has-arrow">{this.props.t("Sales Documents")}</Link>
                          <ul className="sub-menu mm-collapse">
                            {salesDocumentView ? <>
                              <li>
                                <Link to="/documents/sales">{this.props.t("Sales Documents")}</Link>
                              </li>
                            </> : <></>}
                            {salesDocumentView ? <>
                              {documentsLogsView ? <>
                                <li>
                                  <Link to="/all/documents/sales/logs">{this.props.t("Logs")}</Link>
                                </li>
                              </> : <></>}
                            </> : <></>}
                          </ul>
                        </li>
                      </> : <></>}
                      {financialsDocumentView ? <>
                        <li>
                          <Link to="/#" className="has-arrow">{this.props.t("Financial Documents")}</Link>
                          <ul className="sub-menu mm-collapse">
                            {financialsDocumentView ? <>
                              <li>
                                <Link to="/payments/documents">{this.props.t("Financial Documents")}</Link>
                              </li>
                            </> : <></>}
                            {financialsDocumentView ? <>
                              {documentsLogsView ? <>
                                <li>
                                  <Link to="/all/documents/payments/logs">{this.props.t("Logs")}</Link>
                                </li>
                              </> : <></>}
                            </> : <></>}
                          </ul>
                        </li>
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {(emailTemplateView || documentTemplateView) ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-book-open" />
                      <span>{this.props.t("Templates")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {documentTemplateView ? <>
                        <li>
                          <Link to="/#" className="has-arrow">
                            <span key="t-documents-templates">
                              {this.props.t("Document Templates")}
                            </span>
                          </Link>
                          <ul className="sub-menu mm-collapse" aria-expanded="false">
                            <li>
                              <Link to="/templates/documents-templates/sales-documents-tabs-view">
                                {this.props.t("Sales Documents")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/templates/documents-templates/financials-documents-tabs-view">
                                {this.props.t("Financials Documents")}{" "}
                              </Link>
                            </li>
                            {documentTemplatesLogs ? <>
                              <li>
                                <Link to="/template/documents/logs">
                                  {this.props.t("Logs")}{" "}
                                </Link>
                              </li>
                            </> : <></>}
                          </ul>
                        </li>
                      </> : <></>}
                      {emailTemplateView ? <>
                        <li>
                          <Link to="/#" className="has-arrow">
                            <span key="t-email-templates">
                              {this.props.t("Email Templates")}
                            </span>
                          </Link>
                          <ul className="sub-menu mm-collapse" aria-expanded="false">
                            <li>
                              <Link to="/templates/email-templates/sales-email-tabs-view">
                                {this.props.t("Sales Emails")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/templates/email-templates/financial-email-tabs-view">
                                {this.props.t("Financial Emails")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/template/email/logs">
                                {this.props.t("Logs")}{" "}
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {/* This menu has been kept open until permissions are completed. */}
                {campaignsCreate || campaignsView || campaignsEdit || campaignsDelete || leadsCreate || leadsView || leadsEdit || leadsDelete || leadsSettingsView ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-store" />
                      <span>{this.props.t("Manage Leads")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse" aria-expanded="false">
                      {campaignsCreate ? <>
                        <li>
                          <Link to="/campaign/add">
                            {this.props.t("Add Campaign")}
                          </Link>
                        </li>
                      </> : <></>}
                      {campaignsView ? <>
                        <li>
                          <Link to="/campaign/list">
                            {this.props.t("List Campaign")}{" "}
                          </Link>
                        </li>
                      </> : <></>}
                      {campaignsView ? <>
                        <li>
                          <Link to="/campaigns/logs">
                            {this.props.t("Campaigns Logs")}{" "}
                          </Link>
                        </li>
                      </> : <></>}
                      {leadsCreate ? <>
                        <li>
                          <Link to="/lead/add">
                            {this.props.t("Add Lead")}
                          </Link>
                        </li>
                      </> : <></>}
                      {leadsView ? <>
                        <li>
                          <Link to="/lead/list">
                            {this.props.t("List Lead")}{" "}
                          </Link>
                        </li>
                      </> : <></>}
                      {leadsView ? <>
                        <li>
                          <Link to="/leads/logs">
                            {this.props.t("Leads Logs")}{" "}
                          </Link>
                        </li>
                      </> : <></>}
                      {/* <li>
                        <Link to="/leads/logs">
                          {this.props.t("Leads Logs")}{" "}
                        </Link>
                      </li> */}
                      {leadsSettingsView ? <>
                        <li>
                          <Link to="/leads-settings/public-api-settings">
                            {this.props.t("Public APIs Settings")}
                          </Link>
                        </li>
                      </> : <></>}
                      {leadsSettingsView ? <>
                        <li>
                          <Link to="/leads-settings/logs">
                            {this.props.t("Lead Settings Logs")}
                          </Link>
                        </li>
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {propertyappzUsersCreate || propertyappzUsersEdit || propertyappzUsersView || propertyappzUsersDelete ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-user" />
                      <span>{this.props.t("Users")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse" aria-expanded="false">
                      {propertyappzUsersCreate ? <>
                        <li>
                          <Link to="/propertyappz/users/add">
                            {this.props.t("Add User")}
                          </Link>
                        </li>
                      </> : <></>}
                      {propertyappzUsersView ? <>
                        <li>
                          <Link to="/propertyappz/users/list">
                            {this.props.t("List Users")}{" "}
                          </Link>
                        </li>
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {propertyappzRolesCreate || propertyappzRolesEdit || propertyappzRolesView || propertyappzRolesDelete ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-lock" />
                      <span>{this.props.t("Roles")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse" aria-expanded="false">
                      {propertyappzRolesCreate ? <>
                        <li>
                          <Link to="/propertyappz/roles/add">
                            {this.props.t("Add Role")}
                          </Link>
                        </li>
                      </> : <></>}
                      {propertyappzRolesView ? <>
                        <li>
                          <Link to="/propertyappz/roles/list">
                            {this.props.t("List Roles")}{" "}
                          </Link>
                        </li>
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {propertyappzTenantCreate || propertyappzTenantEdit || propertyappzTenantView || propertyappzTenantDelete ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-group" />
                      <span>{this.props.t("Tenants")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse" aria-expanded="false">
                      {propertyappzTenantCreate ? <>
                        <li>
                          <Link to="/propertyappz/tenants/add">
                            {this.props.t("Add Tenant")}
                          </Link>
                        </li>
                      </> : <></>}
                      {propertyappzTenantView ? <>
                        <li>
                          <Link to="/propertyappz/tenants/list">
                            {this.props.t("List Tenants")}{" "}
                          </Link>
                        </li>
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {localStorage.getItem("propertyAppzLoginFlag") ? <>
                  {1 ? <>
                    <li>
                      <Link to="/#" className="has-arrow">
                        <i className="bx bx-wrench" />
                        <span>{this.props.t("Settings")}</span>
                      </Link>
                      <ul className="sub-menu mm-collapse" aria-expanded="false">
                        {1 ? <>
                          <li>
                            <Link to="/propertyappz/ip-management/update">
                              {this.props.t("IP Management")}
                            </Link>
                          </li>
                        </> : <></>}
                      </ul>
                    </li>
                  </> : <></>}
                </> : <></>}
                {salesReportView || paymentReportsView || overdueReportsView || projectReportsView ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-file" />
                      <span>{this.props.t("Reports")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {/* <li>
                      <Link to="/#">{this.props.t("Sales Reports")}</Link>
                    </li> */}
                      {salesReportView ? <>
                        <li>
                          <Link to="/report/sales">
                            {this.props.t("Sales Report")}
                          </Link>
                        </li>
                      </> : <></>}
                      {paymentReportsView ? <>
                        <li>
                          <Link to="/report/payments">

                            {this.props.t("Payments Report")}
                          </Link>
                        </li>
                      </> : <></>}
                      {overdueReportsView ? <>
                        <li>
                          <Link to="/report/overdue-payments">
                            {this.props.t("Overdue Report")}
                          </Link>
                        </li>
                      </> : <></>}
                      {projectReportsView ? <>
                        <li>
                          <Link to="/reports/projects">{this.props.t("Project Reports")}</Link>
                        </li>
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {settingView ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-wrench" />
                      <span>{this.props.t("Settings")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      {orgOrgView || orgOrgEdit ? <>
                        <li>
                          <Link to="/setting/organisation/setup">
                            <span key="t-email-templates">
                              {this.props.t("Organisation")}
                            </span>
                          </Link>
                        </li>
                      </> : <></>}
                      {roleCreate || roleDelete || roleView || roleEdit ? <>
                        <li>
                          <Link to="/#" className="has-arrow">
                            <span key="t-email-templates">
                              {this.props.t("Roles")}
                            </span>
                          </Link>
                          <ul className="sub-menu mm-collapse" aria-expanded="false">
                            {roleCreate ? <>
                              <li>
                                <Link to="/roles/add">
                                  {this.props.t("Add Role")}
                                </Link>
                              </li>
                            </> : <></>}
                            <li>
                              <Link to="/roles/list">
                                {this.props.t("List Roles")}{" "}
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </> : <></>}
                      {userCreate || userDelete || userView || userEdit ? <>
                        <li>
                          <Link to="/#" className="has-arrow">
                            <span key="t-email-templates">
                              {this.props.t("Users")}
                            </span>
                          </Link>
                          <ul className="sub-menu mm-collapse" aria-expanded="false">
                            {userCreate ? <>
                              <li>
                                <Link to="/organisation/users/add">
                                  {this.props.t("Add User")}
                                </Link>
                              </li>
                            </> : <></>}
                            <li>
                              <Link to="/organisation/users/list">
                                {this.props.t("List Users")}{" "}
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </> : <></>}
                      {/* Bank Details Open Menu */}
                      {1 ? <>
                        <li>
                          <Link to="/#" className="has-arrow">
                            <span key="t-email-templates">
                              {this.props.t("Banks")}
                            </span>
                          </Link>
                          <ul className="sub-menu mm-collapse" aria-expanded="false">
                            {1 ? <>
                              <li>
                                <Link to="/organisation/bank/add">
                                  {this.props.t("Add Bank")}
                                </Link>
                              </li>
                            </> : <></>}
                            <li>
                              <Link to="/organisation/bank/list">
                                {this.props.t("List Banks")}{" "}
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </> : <></>}
                    </ul>
                  </li>
                </> : <></>}
                {citiesCreate || citiesDelete || citiesView || citiesEdit ? <>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <i className="bx bx-book-open" />
                      <span>{this.props.t("Catalogue")}</span>
                    </Link>
                    <ul className="sub-menu mm-collapse">
                      <li>
                        <Link to="/#" className="has-arrow">
                          <span key="t-email-templates">
                            {this.props.t("Cities")}
                          </span>
                        </Link>
                        <ul className="sub-menu mm-collapse" aria-expanded="false">
                          {citiesCreate ? <>
                            <li>
                              <Link to="/catalogue/cities/add">
                                {this.props.t("Add City")}
                              </Link>
                            </li>
                          </> : <></>}
                          <li>
                            <Link to="/catalogue/cities/list">
                              {this.props.t("List Cities")}{" "}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </> : <></>}
                {/* <li>
                <Link to="/calendar" className="">
                  <i className="bx bx-calendar" />
                  <span>{this.props.t("Calendar")}</span>
                </Link>
              </li>
              <li>
                <Link to="/chat" className="">
                  <i className="bx bx-chat" />
                  <span>{this.props.t("Chat")}</span>
                </Link>
              </li>
              <li>
                <Link to="/apps-filemanager" className="">
                  <i className="bx bx-file" />
                  <span>{this.props.t("File Manager")}</span>
                </Link>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-store" />
                  <span>{this.props.t("Ecommerce")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/ecommerce-products">
                      {this.props.t("Products")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ecommerce-product-details/1">
                      {this.props.t("Product Details")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ecommerce-orders">{this.props.t("Orders")}</Link>
                  </li>
                  <li>
                    <Link to="/ecommerce-customers">
                      {this.props.t("Customers")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ecommerce-cart">{this.props.t("Cart")}</Link>
                  </li>
                  <li>
                    <Link to="/ecommerce-checkout">
                      {this.props.t("Checkout")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ecommerce-shops">{this.props.t("Shops")}</Link>
                  </li>
                  <li>
                    <Link to="/ecommerce-add-product">
                      {this.props.t("Add Product")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-bitcoin" />
                  <span>{this.props.t("Crypto")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/crypto-wallet">{this.props.t("Wallet")}</Link>
                  </li>
                  <li>
                    <Link to="/crypto-buy-sell">
                      {this.props.t("Buy/Sell")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/crypto-exchange">
                      {this.props.t("Exchange")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/crypto-lending">{this.props.t("Lending")}</Link>
                  </li>
                  <li>
                    <Link to="/crypto-orders">{this.props.t("Orders")}</Link>
                  </li>
                  <li>
                    <Link to="/crypto-kyc-application">
                      {this.props.t("KYC Application")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/crypto-ico-landing">
                      {this.props.t("ICO Landing")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-envelope"></i>
                  <span>{this.props.t("Email")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/email-inbox">{this.props.t("Inbox")}</Link>
                  </li>
                  <li>
                    <Link to="/email-read">{this.props.t("Read Email")} </Link>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow">
                      <span key="t-email-templates">
                        {this.props.t("Templates")}
                      </span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/email-template-basic">
                          {this.props.t("Basic Action")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/email-template-alert">
                          {this.props.t("Alert Email")}{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/email-template-billing">
                          {this.props.t("Billing Email")}{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-receipt" />
                  <span>{this.props.t("Invoices")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/invoices-list">
                      {this.props.t("Invoice List")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/invoices-detail">
                      {this.props.t("Invoice Detail")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-briefcase-alt-2" />
                  <span>{this.props.t("Projects")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/projects-grid">
                      {this.props.t("Projects Grid")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/projects-list">
                      {this.props.t("Projects List")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/projects-overview">
                      {this.props.t("Project Overview")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/projects-create">
                      {this.props.t("Create New")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-task" />
                  <span>{this.props.t("Tasks")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/tasks-list">{this.props.t("Task List")}</Link>
                  </li>
                  <li>
                    <Link to="/tasks-create">
                      {this.props.t("Create Task")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-user-detail" />
                  <span>{this.props.t("Contacts")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/contacts-grid">{this.props.t("User Grid")}</Link>
                  </li>
                  <li>
                    <Link to="/contacts-list">{this.props.t("User List")}</Link>
                  </li>
                  <li>
                    <Link to="/contacts-profile">
                      {this.props.t("Profile")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-detail" />
                  <span>{this.props.t("Blog")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/blog-list">{this.props.t("Blog List")}</Link>
                  </li>
                  <li>
                    <Link to="/blog-grid">{this.props.t("Blog Grid")}</Link>
                  </li>
                  <li>
                    <Link to="/blog-details">
                      {this.props.t("Blog Details")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#">
                  <span className="badge rounded-pill bg-success float-end" key="t-new">New</span>
                  <i className="bx bx-briefcase-alt"></i>
                  <span key="t-jobs">{this.props.t("Jobs")}</span>
                </Link>
                <ul className="sub-menu">
                  <li><Link to="/job-list">{this.props.t("Job List")}</Link></li>
                  <li><Link to="/job-grid">{this.props.t("Job Grid")}</Link></li>
                  <li><Link to="/job-apply">{this.props.t("Apply Job")}</Link></li>
                  <li><Link to="/job-details">{this.props.t("Job Details")}</Link></li>
                  <li><Link to="/job-categories">{this.props.t("Jobs Categories")}</Link></li>
                  <li>
                    <Link to="/#" className="has-arrow">{this.props.t("Candidate")}</Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li><Link to="/candidate-list">{this.props.t("List")}</Link></li>
                      <li><Link to="/candidate-overview">{this.props.t("Overview")}</Link></li>
                    </ul>
                  </li>
                </ul>
              </li> */}

                {/* <li className="menu-title">Pages</li>
              <li>
                <Link to="/#" className="has-arrow ">
                  <i className="bx bx-user-circle" />
                  <span>{this.props.t("Authentication")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/pages-login">{this.props.t("Login")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-login-2">{this.props.t("Login 2")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-register">{this.props.t("Register")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-register-2">
                      {this.props.t("Register 2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/page-recoverpw">
                      {this.props.t("Recover Password")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages-recoverpw-2">
                      {this.props.t("Recover Password 2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth-lock-screen">
                      {this.props.t("Lock Screen")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth-lock-screen-2">
                      {this.props.t("Lock Screen 2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/page-confirm-mail">
                      {this.props.t("Confirm Mail")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/page-confirm-mail-2">
                      {this.props.t("Confirm Mail 2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth-email-verification">
                      {this.props.t("Email Verification")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth-email-verification-2">
                      {this.props.t("Email Verification 2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth-two-step-verification">
                      {this.props.t("Two Step Verification")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/auth-two-step-verification-2">
                      {this.props.t("Two Step Verification 2")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-file" />
                  <span>{this.props.t("Utility")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/pages-starter">
                      {this.props.t("Starter Page")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages-maintenance">
                      {this.props.t("Maintenance")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages-comingsoon">
                      {this.props.t("Coming Soon")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/pages-timeline">{this.props.t("Timeline")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-faqs">{this.props.t("FAQs")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-pricing">{this.props.t("Pricing")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-404">{this.props.t("Error 404")}</Link>
                  </li>
                  <li>
                    <Link to="/pages-500">{this.props.t("Error 500")}</Link>
                  </li>
                </ul>
              </li>

              <li className="menu-title">{this.props.t("Components")}</li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-tone" />
                  <span>{this.props.t("UI Elements")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/ui-alerts">{this.props.t("Alerts")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-buttons">{this.props.t("Buttons")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-cards">{this.props.t("Cards")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-carousel">{this.props.t("Carousel")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-dropdowns">{this.props.t("Dropdowns")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-grid">{this.props.t("Grid")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-images">{this.props.t("Images")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-lightbox">{this.props.t("Lightbox")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-modals">{this.props.t("Modals")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-offcanvas">{this.props.t("OffCanvas")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-rangeslider">
                      {this.props.t("Range Slider")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ui-session-timeout">
                      {this.props.t("Session Timeout")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ui-progressbars">
                      {this.props.t("Progress Bars")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ui-placeholders">{this.props.t("Placeholders")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-tabs-accordions">
                      {this.props.t("Tabs & Accordions")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ui-typography">
                      {this.props.t("Typography")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ui-toasts">{this.props.t("Toasts")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-video">{this.props.t("Video")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-general">{this.props.t("General")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-colors">{this.props.t("Colors")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-rating">{this.props.t("Rating")}</Link>
                  </li>
                  <li>
                    <Link to="/ui-notifications">
                      {this.props.t("Notifications")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/ui-utilities">
                      <span className="badge rounded-pill bg-success float-end">
                        {this.props.t("New")}
                      </span>
                      {this.props.t("Utilities")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#">
                  <i className="bx bxs-eraser" />
                  <span className="badge rounded-pill bg-danger float-end">
                    10
                  </span>
                  <span>{this.props.t("Forms")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/form-elements">
                      {this.props.t("Form Elements")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/form-layouts">
                      {this.props.t("Form Layouts")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/form-validation">
                      {this.props.t("Form Validation")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/form-advanced">
                      {this.props.t("Form Advanced")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/form-editors">
                      {this.props.t("Form Editors")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/form-uploads">
                      {this.props.t("Form File Upload")}{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to="/form-xeditable">
                      {this.props.t("Form Xeditable")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/form-repeater">
                      {this.props.t("Form Repeater")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/form-wizard">{this.props.t("Form Wizard")}</Link>
                  </li>
                  <li>
                    <Link to="/form-mask">{this.props.t("Form Mask")}</Link>
                  </li>
                  <li>
                    <Link to="/dual-listbox">
                      {this.props.t("Transfer List")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-list-ul" />
                  <span>{this.props.t("Tables")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/tables-basic">
                      {this.props.t("Basic Tables")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/tables-datatable">
                      {this.props.t("Data Tables")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/tables-responsive">
                      {this.props.t("Responsive Table")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/tables-editable">
                      {this.props.t("Editable Table")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/tables-dragndrop">
                      {this.props.t("Drag & Drop Table")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-bar-chart-alt-2" />
                  <span>{this.props.t("Charts")}</span>
                </Link>

                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/apex-charts">{this.props.t("Apex charts")}</Link>
                  </li>
                  <li>
                    <Link to="/e-charts">{this.props.t("E Chart")}</Link>
                  </li>
                  <li>
                    <Link to="/chartjs-charts">
                      {this.props.t("Chartjs Chart")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/chartist-charts">
                      {this.props.t("Chartist Chart")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/charts-knob">{this.props.t("Knob Charts")}</Link>
                  </li>
                  <li>
                    <Link to="/sparkline-charts">
                      {this.props.t("Sparkline Chart")}
                    </Link>
                  </li>

                  <li>
                    <Link to="/re-charts">{this.props.t("Re Chart")}</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-aperture" />
                  <span>{this.props.t("Icons")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/icons-boxicons">{this.props.t("Boxicons")}</Link>
                  </li>
                  <li>
                    <Link to="/icons-materialdesign">
                      {this.props.t("Material Design")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/icons-dripicons">
                      {this.props.t("Dripicons")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/icons-fontawesome">
                      {this.props.t("Font awesome")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-map" />
                  <span>{this.props.t("Maps")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/maps-google">{this.props.t("Google Maps")}</Link>
                  </li>
                  <li>
                    <Link to="/maps-vector">{this.props.t("Vector Maps")}</Link>
                  </li>
                  <li>
                    <Link to="/maps-leaflet">
                      {this.props.t("Leaflet Maps")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-share-alt" />
                  <span>{this.props.t("Multi Level")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <Link to="#">{this.props.t("Level 1.1")}</Link>
                  </li>
                  <li>
                    <Link to="#" className="has-arrow">
                      {this.props.t("Level 1.2")}
                    </Link>
                    <ul className="sub-menu" aria-expanded="true">
                      <li>
                        <Link to="#">{this.props.t("Level 2.1")}</Link>
                      </li>
                      <li>
                        <Link to="#">{this.props.t("Level 2.2")}</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li> */}
              </>}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
